import React, { JSX } from "react";
import wonderImgFile from "../../assets/wonders_v3_2X.jpg";
import { CardRecord } from "../index";
import { ALL_WONDER_DATA } from "../../GameInit";

export default function WonderView(props: { data: CardRecord }): JSX.Element {
    const { name, showBack } = props.data
    const [locRow, locCol] = ALL_WONDER_DATA[name].getLoc()
    const x = showBack ? 1 : locCol
    const y = showBack ? 3 : locRow

    return (
        <div className={'w-full h-full flex justify-center items-end rounded-md'}
             style={{
                 backgroundImage: `url(${wonderImgFile})`,
                 backgroundSize: '500% 400%',
                 backgroundPositionX: `-${x}00%`,
                 backgroundPositionY: `-${y}00%`,
             }}>
        <span className={'w-2/3 text-center bg-white rounded-lg opacity-100'}
              style={{
                  fontSize: '1em',
                  marginBottom: '0.5em'
              }}>{name}</span>
        </div>
    )
}

