import {RefObject, useState} from "react";
import {GameRoomRef, SocketMessage} from "../game-room";
import {MSG_CHANGE_SCORE_PANEL, MSG_FROM_A} from "../../constants";
import {Player} from "../../type/Player";

export const ScorePanel = (props: {
    gameRoomRef: RefObject<GameRoomRef>,
    iAmPlayerA: boolean,
    playerA: Player,
    playerB: Player,
}) => {
    const [playerAScores, setPlayerAScores] = useState<{ [key: string]: number }>(props.playerA.getOwnedScores())
    const [playerBScores, setPlayerBScores] = useState<{ [key: string]: number }>(props.playerB.getOwnedScores())

    const gameRoom = (props.gameRoomRef)?.current
    gameRoom?.addMsgListener('score-panel', (msg: SocketMessage) => {
        if (msg.text === MSG_CHANGE_SCORE_PANEL && (msg.from === MSG_FROM_A) !== props.iAmPlayerA) {
            setPlayerAScores(msg.extra.playerAScores)
            setPlayerBScores(msg.extra.playerBScores)
        }
    })

    const onInput = (content: string, type: string, isPlayerA: boolean) => {
        let value = content
        value = value.replace(/\D/, '')
        if (!value) {
            value = '0'
        } else if (value.length > 1 && value.startsWith('0')) {
            value = value.slice(1)
        }
        if (isPlayerA) {
            playerAScores[type] = Number(value)
            setPlayerAScores({ ...playerAScores })
        } else {
            playerBScores[type] = Number(value)
            setPlayerBScores({ ...playerBScores })
        }
        gameRoom?.sendMsg(MSG_CHANGE_SCORE_PANEL, { playerAScores, playerBScores })
    }

    return (
        <div className={'fixed top-0 left-0 flex h-full w-full justify-center z-40 bg-black bg-opacity-40'}>
            <div
                className={'absolute p-8 w-96 top-60 flex flex-col items-center justify-center bg-blue-100 border-2 border-black rounded-3xl z-40'}>
                <div className={'flex text-xl p-1'}>
                    <div className={'w-36'}>Player:</div>
                    <div className={'w-12'}>A</div>
                    <div className={'w-12'}>B</div>
                </div>
                {Object.keys(playerAScores).map((type, index) => {
                    return <div className={'flex text-xl p-1'} id={'' + index}>
                        <div className={'w-36'}>{type}</div>
                        <input className={'w-12 mx-1'} value={playerAScores[type]}
                               onChange={(e) => onInput(e.target.value, type, true)}/>
                        <input className={'w-12 mx-1'} value={playerBScores[type]}
                               onChange={(e) => onInput(e.target.value, type, false)}/>
                    </div>
                })}
                <div className={'flex text-3xl'}>
                    <div className={'w-36'}>总分</div>
                    <div
                        className={'w-12'}>{Object.values(playerAScores).reduce((accumulator: number, currentValue: number) => accumulator + currentValue, 0)}</div>
                    <div
                        className={'w-12'}>{Object.values(playerBScores).reduce((accumulator: number, currentValue: number) => accumulator + currentValue, 0)}</div>
                </div>
            </div>
        </div>
    )
}

export default ScorePanel
