import {
    BUILDING_HEIGHT,
    BUILDING_TEXT_SIZE,
    BUILDING_WIDTH,
    len,
    MSG_CLICK_DISCARDED_POOL_BUILDING
} from "../../constants";
import BuildingView from "../../card/building-view";
import React, { forwardRef, Ref, RefObject, useImperativeHandle, useState } from "react";
import { GameRoomRef, SocketMessage } from "../game-room";
import { CardRecord } from "../../card";
import { CardTipRef } from "../temper-contaniers/card-tip";

export declare type DiscardedCardsContainerRef = {
    addANewDiscardedCard: (name: string) => void
    getNeedSyncData: () => string[]
    onReceiveSyncData: (data: string[]) => void
}

const DiscardedCardsContainer = forwardRef((props: {
    isMyPhase: boolean,
    cardTipRef: RefObject<CardTipRef>
    gameRoomRef: RefObject<GameRoomRef>
}, ref: Ref<DiscardedCardsContainerRef>) => {
    useImperativeHandle(ref, () => ({
        addANewDiscardedCard: (name: string) => {
            discardedPoolCards.push(name)
            setDiscardedPoolCards(discardedPoolCards.slice())
        },
        getNeedSyncData: () => discardedPoolCards,
        onReceiveSyncData: (data: string[]) => {
            setDiscardedPoolCards(data)
        }
    }))

    const [discardedPoolCards, setDiscardedPoolCards] = useState<string[]>([])

    const createOneBuilding = (name: string) => {
        const buildHeight = BUILDING_HEIGHT * 0.3
        const cardRecord: CardRecord = {
            cardType: '建筑', createBy: '', name: name, showBack: false
        }

        return (
            <div className={'relative flex m-px'}
                 key={name}
                 style={{
                     width: len(BUILDING_WIDTH),
                     height: buildHeight,
                 }}>
                <div className={'absolute'} style={{
                    width: len(BUILDING_WIDTH),
                    height: len(BUILDING_HEIGHT),
                    fontSize: len(BUILDING_TEXT_SIZE),
                }}
                     onClick={() => {
                         if (props.isMyPhase) {
                             props.gameRoomRef?.current?.sendMsg(MSG_CLICK_DISCARDED_POOL_BUILDING, name)
                             props.cardTipRef?.current?.onMouseLeave()
                         }
                     }}
                     onTouchStart={() => props.cardTipRef?.current?.onMouseEnter(cardRecord)}
                     onTouchMove={(e) => props.cardTipRef?.current?.onMouseMove(e.touches.item(0).clientX, e.touches.item(0).clientY)}
                     onTouchEnd={() => props.cardTipRef?.current?.onMouseLeave()}
                     onMouseEnter={() => props.cardTipRef?.current?.onMouseEnter(cardRecord)}
                     onMouseMove={(e) => props.cardTipRef?.current?.onMouseMove(e.clientX, e.clientY)}
                     onMouseLeave={() => props.cardTipRef?.current?.onMouseLeave()}
                >
                    <BuildingView data={cardRecord}/>
                </div>
            </div>
        )
    }

    props?.gameRoomRef?.current?.addMsgListener('discarded-cards-container', (msg: SocketMessage) => {
        if (msg.text === MSG_CLICK_DISCARDED_POOL_BUILDING) {
            const name = msg.extra
            const newDiscardedPool = discardedPoolCards.filter(v => v !== name)
            setDiscardedPoolCards(newDiscardedPool)
        }
    })

    return (
        <div className={'relative flex w-full min-h-32 items-center bg-gray-300 rounded-2xl border-black border-2 '}>
            <span className={'absolute w-full text-3xl opacity-50 text-center'}>弃牌堆</span>
            <div
                className={'flex w-full flex-wrap justify-center items-center content-start pt-1 pb-20'}>
                {discardedPoolCards.map(data => createOneBuilding(data))}
            </div>
        </div>
    )
})

export default DiscardedCardsContainer