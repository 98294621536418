import ProgressTokenView from "../../card/progress-token-view";
import { len, MSG_CLICK_PROGRESS_TOKEN } from "../../constants";
import { RefObject } from "react";
import { GameRoomRef } from "../game-room";

const ProgressTokenChooser = (props: {
    gameRoomRef: RefObject<GameRoomRef>,
    tokens?: string[],
}) => {
    if (!props.tokens) return <></>

    return (
        <div className={'fixed top-0 left-0 flex h-full w-full justify-center z-40 bg-black bg-opacity-40'}>
        <div
            className={'absolute w-96 top-80 flex flex-row items-center justify-center bg-blue-100 border-2 border-black rounded-3xl p-8 z-40'}
            style={{
                width: len(380),
                height: len(140),
            }}>
            {props.tokens.map((value, index) =>
                <div key={index}
                     onClick={() => props.gameRoomRef?.current?.sendMsg(MSG_CLICK_PROGRESS_TOKEN, value)}>
                    <div className={'w-20 h-20 mx-4'}>
                        <ProgressTokenView
                            data={{ cardType: '发展标记', createBy: '', name: value, showBack: false }}/>
                    </div>
                </div>
            )}
        </div>
        </div>
    )

}

export default ProgressTokenChooser