import Papa, {ParseResult} from "papaparse";
import {ALL_BUILDING_DATA, ALL_PROGRESS_DATA, ALL_WONDER_DATA} from "./GameInit";
import {BuildingType} from "./type/Building";

export const readCSV = (url: string, callBack: (data: any[]) => void) => {
    Papa.parse(url, {
        header: true,
        download: true,
        skipEmptyLines: true,
        delimiter: ",",
        complete: (results: ParseResult<any>) => {
            callBack(results.data)
        },
    })
}

export function prepareBuildingDataListByTimes(times: number): string[] {
    let targetTimesBuildings: string[] = []
    if (times !== 3) {
        targetTimesBuildings = Object.values(ALL_BUILDING_DATA).filter(v => v.getTimes() === times && v.getBuildingType() !== '背景').map(v => v.getName())
    } else {
        const a = Object.values(ALL_BUILDING_DATA).filter(v => v.getTimes() === 3 && v.getBuildingType() !== '背景').map(v => v.getName()).sort(() => 0.5 - Math.random()).slice(0, 17)
        const b = Object.values(ALL_BUILDING_DATA).filter(v => v.getTimes() === 4 && v.getBuildingType() !== '背景').map(v => v.getName()).sort(() => 0.5 - Math.random()).slice(0, 3)
        targetTimesBuildings = a.concat(b)
    }
    return targetTimesBuildings.sort(() => 0.5 - Math.random()).slice(0, 20)
}

export function prepareProgressTokens(): string[] {
    const targetTokens = Object.values(ALL_PROGRESS_DATA)
        .filter(v => v.getName() !== '背景')
        .map(v => v.getName())
    return targetTokens.sort(() => 0.5 - Math.random()).slice(0, 10)
}

export function prepareWonders(): string[] {
    const targetWonders = Object.values(ALL_WONDER_DATA).filter(v => v.getName() !== '背景').map(v => v.getName())
    return targetWonders.sort(() => 0.5 - Math.random()).slice(0, 8)
}

export function parseOfferMaterialStr(offerMaterialStr: string) {
    let isFactory = false
    let omnipotent = [0, 0]           // 木头/石料/粘土, 纸张/玻璃 替换材料数量
    let materials = [0, 0, 0, 0, 0]   // 木头/石料/粘土/纸张/玻璃
    if (offerMaterialStr.startsWith("工厂-")) {
        offerMaterialStr = offerMaterialStr.slice("工厂-".length)
        isFactory = true
    } else if (offerMaterialStr.includes('/')) {
        if (offerMaterialStr.includes('木头') && offerMaterialStr.includes('粘土') && offerMaterialStr.includes('石料')) {
            omnipotent[0] = 1
        } else if (offerMaterialStr.includes('玻璃') && offerMaterialStr.includes('纸张')) {
            omnipotent[1] = 1
        }
    }

    let num = 1
    if (offerMaterialStr.includes('*')) {
        const split = offerMaterialStr.split('*')
        offerMaterialStr = split[0]
        num = split[1] ? Number(split[1]) : 1
    }
    switch (offerMaterialStr) {
        case '木头':
            materials[0] = num
            break
        case '石料':
            materials[1] = num
            break
        case '粘土':
            materials[2] = num
            break
        case '纸张':
            materials[3] = num
            break
        case '玻璃':
            materials[4] = num
            break
        case '纸张/玻璃':
            materials[3] = num
            materials[4] = num
            break
    }
    return {
        materials: isFactory ? [] : materials,
        factories: isFactory ? materials : [],
        omnipotent,
    }
}

export const parseOfferGolds = (offerGoldsStr: string): number | { types: string[], num: number } => {
    if (!offerGoldsStr) return 0
    if (!Number.isNaN(Number(offerGoldsStr))) {
        return Number(offerGoldsStr)
    }
    const type = offerGoldsStr.split('*')[0]
    const num = Number(offerGoldsStr.split('*')[1])
    if (type.includes('+')) {
        const types = type.slice(1, -1).split('+')
        return { types, num }
    } else {
        return { types: [type], num }
    }
}

export const parseOfferScores = (offerScoresStr: string): number | { types: string[], num: number } => {
    if (!offerScoresStr) return 0
    if (!Number.isNaN(Number(offerScoresStr))) {
        return Number(offerScoresStr)
    }
    const type = offerScoresStr.split('*')[0]
    const num = Number(offerScoresStr.split('*')[1])
    if (type.includes('+')) {
        const types = type.slice(1, -1).split('+')
        return { types, num }
    } else {
        return { types: [type], num }
    }
}

export const getCurrentTimeString = (): string => {
    let date = new Date()
    let year = date.getFullYear()
    let month = (date.getMonth() + 1).toString().padStart(2, '0')
    let day = date.getDate().toString().padStart(2, '0')
    let hours = date.getHours().toString().padStart(2, '0')
    let minutes = date.getMinutes().toString().padStart(2, '0')
    let seconds = date.getSeconds().toString().padStart(2, '0')
    return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds
}

export const arrayAdd = (arr1: number[], arr2: number[]): number[] => {
    const result: number[] = [];
    const length = Math.max(arr1.length, arr2.length);
    for (let i = 0; i < length; i++) {
        const sum = (arr1[i] || 0) + (arr2[i] || 0);
        result.push(sum);
    }
    return result;
};