import {forwardRef, Ref, useEffect, useImperativeHandle, useRef, useState} from "react";
import BuildingView from "../../card/building-view";
import WonderView from "../../card/wonder-view";
import {ProgressTokenView} from "../../card/progress-token-view";
import {BUILDING_HEIGHT, BUILDING_WIDTH, len, WONDER_HEIGHT, WONDER_WIDTH} from "../../constants";
import {CardRecord} from "../../card";
import {ALL_BUILDING_DATA, ALL_PROGRESS_DATA, ALL_WONDER_DATA} from "../../GameInit";
import {Player} from "../../type/Player";

export declare type CardTipRef = {
    onMouseEnter: (card: CardRecord | undefined) => void,
    onMouseMove: (clientX: number, clientY: number) => void,
    onMouseLeave: () => void,
}

const CardTip = forwardRef((props: {
    leftPlayer: Player,
    rightPlayer: Player,
    iAmLeftPlayer: boolean,
}, ref: Ref<CardTipRef>) => {
    const [clientWidth, setClientWidth] = useState(window.innerWidth);
    const [clientHeight, setClientHeight] = useState(window.innerHeight);

    const [cardData, setCardData] = useState<CardRecord>()
    const [mouseXY, setMouseXY] = useState<{ x: number, y: number }>()
    const timeOutId = useRef<any>()
    const myPlayer = props.iAmLeftPlayer ? props.leftPlayer : props.rightPlayer
    const oppositePlayer = props.iAmLeftPlayer ? props.rightPlayer : props.leftPlayer

    useEffect(() => {
        const handleResize = () => {
            setClientWidth(window.innerWidth);
            setClientHeight(window.innerHeight);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useImperativeHandle(ref, () => ({
        onMouseEnter: (card) => {
            timeOutId.current = setTimeout(() => {
                setCardData(card)
            }, 500)
        },
        onMouseMove: (clientX: number, clientY: number) => {
            setMouseXY({ x: clientX, y: clientY })
        },
        onMouseLeave: () => {
            setCardData(undefined)
            clearInterval(timeOutId.current)
        }
    }))

    let cardView = <></>
    let cardDesc = ''
    let buildCost = 0
    let oppositeBuildCost = 0
    if (cardData?.cardType === '建筑') {
        cardView = <div className={'flex'} style={{
            width: len(BUILDING_WIDTH * 2),
            height: len(BUILDING_HEIGHT * 2),
        }}>
            <BuildingView data={cardData}/>
        </div>
        cardDesc = ALL_BUILDING_DATA[cardData.name].getName()
        buildCost = myPlayer.getBuildingCost(cardData.name).totalCost
        oppositeBuildCost = oppositePlayer.getBuildingCost(cardData.name).totalCost
    } else if (cardData?.cardType === '奇迹') {
        cardView = <div className={'flex'} style={{
            width: len(WONDER_WIDTH * 2),
            height: len(WONDER_HEIGHT * 2),
        }}>
            <WonderView data={cardData}/>
        </div>
        cardDesc = ALL_WONDER_DATA[cardData.name].getName() + '\n' + ALL_WONDER_DATA[cardData.name].getDesc()
        buildCost = myPlayer.getWonderCost(cardData.name).totalCost
        oppositeBuildCost = oppositePlayer.getWonderCost(cardData.name).totalCost
    } else if (cardData?.cardType === '发展标记') {
        cardView = <div className={'flex'} style={{
            width: len(96),
            height: len(96),
        }}>
            <ProgressTokenView data={cardData}/>
        </div>
        cardDesc = ALL_PROGRESS_DATA[cardData.name].getDesc()
    }

    const safeWidth = (cardData?.cardType === '奇迹' ? WONDER_WIDTH * 2 : BUILDING_WIDTH * 2) + 180
    const left = Math.min(mouseXY?.x ?? 0, clientWidth - safeWidth)
    const top = Math.min(mouseXY?.y ?? 0, clientHeight - BUILDING_HEIGHT * 2)

    const ownedResource = myPlayer.getOwnedResource()
    return <>
        {cardData && !cardData.showBack &&
            <div className={'fixed flex z-50 bg-black bg-opacity-80 p-2 rounded-lg'}
                 style={{
                     pointerEvents: 'none',
                     left: len(left),
                     top: len(top),
                 }}>
                {cardView}

                <div className={'flex-col mx-4 text-white'}>
                    <div style={{ whiteSpace: 'pre-wrap', }}>{cardDesc}</div>
                    <br/>
                    {cardData?.cardType === '奇迹' && <>
                        <div>建造消耗金币：{buildCost}</div>
                        <div>对手建造消耗：{oppositeBuildCost}</div>
                        <div>卖掉可得金币：{myPlayer.getSellerPrice()}</div>
                        <div>调试信息：</div>
                        <div>需要材料：{ALL_WONDER_DATA[cardData.name].getRequireMaterials()}</div>
                        <div>提供万能材料：{ALL_WONDER_DATA[cardData.name].getOfferOmnipotent()}</div>
                        <div>我有的材料：{ownedResource.materials}</div>
                        <div>我有的工厂：{ownedResource.factories}</div>
                        <div>我有的万能材料：{ownedResource.omnipotent}</div>
                    </>}
                    {cardData?.cardType === '建筑' && <>
                        <div>建造消耗金币：{buildCost}</div>
                        <div>对手建造消耗：{oppositeBuildCost}</div>
                        <div>卖掉可得金币：{myPlayer.getSellerPrice()}</div>
                        <div>调试信息：</div>
                        <div>需要材料：{ALL_BUILDING_DATA[cardData.name].getRequireMaterials()}</div>
                        <div>提供材料：{ALL_BUILDING_DATA[cardData.name].getOfferMaterial()}</div>
                        <div>提供工厂：{ALL_BUILDING_DATA[cardData.name].getOfferFactory()}</div>
                        <div>提供万能材料：{ALL_BUILDING_DATA[cardData.name].getOfferOmnipotent()}</div>
                        <div>我有的材料：{ownedResource.materials}</div>
                        <div>我有的工厂：{ownedResource.factories}</div>
                        <div>我有的万能材料：{ownedResource.omnipotent}</div>
                    </>}
                </div>
            </div>
        }
    </>
})

export default CardTip