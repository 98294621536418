import ProgressTokenView from "../../card/progress-token-view";
import spritesFile from "../../assets/sprites_2X.png"
import { RefObject } from "react";
import { GameRoomRef } from "../game-room";
import { MSG_CHANGE_GOLD, MSG_CHANGE_PLAYER_PHASE, MSG_CLICK_REVERT_PHASE } from "../../constants";
import { Player } from "../../type/Player";

const createOneProgressToken = (name: string) => {
    return (
        <div className={'m-0.5 w-12 h-12'} key={name}>
            <ProgressTokenView data={{ name: name, showBack: false, cardType: '发展标记', createBy: '' }}/>
        </div>
    )
}

const Gold = (props: {
    num: number,
    isLeftPlayer: boolean,
    showChangeBtn: boolean,
    canChange: boolean,
    onChangeGoldsTo: (num: number) => void      // TODO(wangguichun): 2024/7/27 可以考虑移除手动变更金币了
}) => {
    const onChangeGoldsTo = (num: number) => {
        if (props.canChange) {
            props.onChangeGoldsTo(num)
        }
    }

    return (
        <div className={'flex h-12 items-center ' + (props.isLeftPlayer ? 'flex-row' : 'flex-row-reverse')}>
            {props.showChangeBtn && <button className={'border-2 border-black w-6 h-6 rounded-full text-center'}
                                            onClick={() => onChangeGoldsTo(Number(props.num) - 1)}>
                ➖
            </button>}
            <input
                className={'flex rounded-full items-center justify-center m-0.5 w-12 h-12 text-2xl font-bold text-center bg-transparent'}
                style={{
                    backgroundImage: `url(${spritesFile})`,
                    backgroundSize: `${512 / 62 * 100}%`,
                    backgroundPositionX: `-${186.6 / 62 * 100}%`,
                    backgroundPositionY: `-${435 / 62 * 100}%`,
                }}
                value={props.num}
                onInput={(e) => {
                    // @ts-ignore
                    let value = e.target.value
                    value = value.replace(/\D/, '')
                    if (!value) {
                        value = '0'
                    } else if (value.length > 1 && value.startsWith('0')) {
                        value = value.slice(1)
                    }
                    onChangeGoldsTo(Number(value))
                }}/>
            {props.showChangeBtn && <button className={'border-2 border-black w-6 h-6 rounded-full'}
                                            onClick={() => onChangeGoldsTo(Number(props.num) + 1)}>
                ➕
            </button>}
        </div>
    )
}

const PlayerInfoContainer = (props: {
    isLeftPlayer: boolean,
    iAmLeftPlayer: boolean,
    isMyPhase: boolean,
    info: Player,
    gameRoomRef: RefObject<GameRoomRef>
}) => {
    const { isLeftPlayer, iAmLeftPlayer, info, gameRoomRef } = props
    const isMyPanel = iAmLeftPlayer === isLeftPlayer
    const isPanelPlayerPhase = isMyPanel === props.isMyPhase

    return (
        <div
            className={'flex flex-col w-full border-2 border-black rounded-2xl px-2 '}>
            <div className={'flex w-full ' + (isLeftPlayer ? 'flex-row' : 'flex-row-reverse')}>
                <h1 className={'font-bold px-2'}>
                    {(isLeftPlayer ? '玩家A' : '玩家B') + (isMyPanel ? '(我)' : '')}
                </h1>
                <div className={'flex flex-grow'}/>
                {isPanelPlayerPhase && (isMyPanel ?
                    <>
                        <button className={'font-bold px-2 border-black border-b-2'}
                                onClick={() => gameRoomRef?.current?.sendMsg(MSG_CLICK_REVERT_PHASE)}
                        >悔棋
                        </button>
                        <div className={'w-1'}/>
                        <button className={'font-bold px-2 border-black border-b-2'}
                                onClick={() => gameRoomRef?.current?.sendMsg(MSG_CHANGE_PLAYER_PHASE)}
                        >回合结束
                        </button>
                    </> :
                    <h1 className={'font-bold px-2 border-black border-b-2'}>行动中...</h1>)
                }
            </div>
            <div
                className={'flex h-14 w-full content-start ' + (isLeftPlayer ? 'flex-row' : 'flex-row-reverse')}>
                <Gold num={info.getGolds()}
                      showChangeBtn={isMyPanel}
                      canChange={props.isMyPhase}
                      isLeftPlayer={isLeftPlayer}
                      onChangeGoldsTo={(num) => gameRoomRef?.current?.sendMsg(MSG_CHANGE_GOLD, {
                          isLeftPlayer: isLeftPlayer,
                          toNum: num
                      })}/>
                {info.getProgressTokens().map((value) => createOneProgressToken(value))}
            </div>
        </div>
    )
}

export default PlayerInfoContainer