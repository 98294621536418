export const len = (n: number): string => n + 'px';
export const BUILDING_WIDTH = 332 * 0.22
export const BUILDING_HEIGHT = 512 / 332 * BUILDING_WIDTH
export const BUILDING_TEXT_SIZE = 7

export const WONDER_WIDTH = 792 * 0.2
export const WONDER_HEIGHT = 504 / 792 * WONDER_WIDTH
export const WONDER_TEXT_SIZE = 7

export const BOARD_WIDTH = 1897
export const BOARD_HEIGHT = 535

// export const webSocketPath = 'ws://localhost:8080/join/'
export const webSocketPath = 'wss://ws.wgspring.cn/join/'
// 消息来源
export const MSG_FROM_SYSTEM = 'System'
export const MSG_FROM_A = 'PlayerA'
export const MSG_FROM_B = 'PlayerB'
// 通用消息
export const MSG_ROOM_CREATED = '房间创建成功，等待玩家加入'
export const MSG_PLAYER_JOIN = '玩家加入房间，当前人数: '
export const MSG_PLAYER_LEAVE = '玩家离开房间，当前人数: '
export const MSG_GAME_START = '游戏开始'
export const MSG_FLIP_READY = "已出拳，等待猜拳结果"
export const MSG_FLIP_RESULT = "猜拳结果"
export const MSG_FLIP_CONFIRM = "确认猜拳结果"
export const MSG_WONDER_DISPATCHER_PHASE_0 = '分发奇迹'
export const MSG_WONDER_DISPATCHER_PHASE_X = '选择奇迹'
export const MSG_PROGRESS_TOKEN_SHOW = '展示发展标记'
export const MSG_DRAFT_POOL_PHASE_1 = '时代1发牌'
export const MSG_DRAFT_POOL_PHASE_2 = '时代2发牌'
export const MSG_DRAFT_POOL_PHASE_3 = '时代3发牌'
export const MSG_DRAFT_POOL_PHASE_4 = '确认开始算分'
export const MSG_CLICK_DRAFT_BUILDING = '点击卡牌堆建筑'
export const MSG_CLICK_DRAFT_BUILDING_RETURN = '点击卡牌堆建筑结果返回'
export const MSG_BUILD_WONDER = '建造奇迹'
export const MSG_CLICK_BUILT_BUILDING = '点击已建建筑'
export const MSG_CLICK_DISCARDED_POOL_BUILDING = '点击废弃建筑'
export const MSG_CLICK_PROGRESS_TOKEN = '点击发展标记'
export const MSG_CLICK_MILITARY_TOKEN = '点击军事标记'
export const MSG_SAVED = '保存数据'
export const MSG_CLICK_REVERT_PHASE = '点击悔棋'
export const MSG_CHANGE_GOLD = '变更金币'
export const MSG_CHANGE_SCORE_PANEL = '变更记分板'
export const MSG_CHANGE_PLAYER_PHASE = '玩家回合结束'


// 同步数据
export const MSG_SYNC_ALL = '发送同步数据'
export const MSG_SYNC_SELECTED_CARDS = '同步选中的建筑'

// 特殊指令
export const MSG_REQUEST_REMOVE_OTHERS = '请求移除其他用户'
export const MSG_ACK = 'ack'   // 收到消息的确认消息
