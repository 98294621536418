import {
    BUILDING_HEIGHT,
    BUILDING_TEXT_SIZE,
    BUILDING_WIDTH,
    len,
    WONDER_HEIGHT,
    WONDER_TEXT_SIZE,
    WONDER_WIDTH
} from "../../constants";
import WonderView from "../../card/wonder-view";
import React, { RefObject } from "react";
import BuildingView from "../../card/building-view";
import { CardTipRef } from "../temper-contaniers/card-tip";
import { CardRecord } from "../../card";

export default function PlayerWonderContainer(props: {
    isLeftPlayer: boolean,
    wonders: (CardRecord | undefined)[],
    cardTipRef: RefObject<CardTipRef>,
    onWonderClick: (belongLeftPlayer: boolean, name: string) => void
}) {
    const { isLeftPlayer, wonders, onWonderClick } = props

    const createOneWounder = (data: CardRecord | undefined) => {
        const createdBy = data?.createBy

        return (
            <div className={'relative border-black border-2 rounded-lg'}
                 style={{
                     marginRight: isLeftPlayer ? len(BUILDING_HEIGHT * 0.2) : "0",
                     marginLeft: isLeftPlayer ? "0" : len(BUILDING_HEIGHT * 0.2),
                     height: len(WONDER_HEIGHT),
                     width: len(WONDER_WIDTH),
                     fontSize: len(WONDER_TEXT_SIZE),
                 }}
                 onClick={() => {
                     if (data && !data.createBy) {
                         onWonderClick(isLeftPlayer, data.name)
                     }
                     props.cardTipRef?.current?.onMouseLeave()
                 }}
                 onTouchStart={() => props.cardTipRef?.current?.onMouseEnter(data)}
                 onTouchMove={(e) => props.cardTipRef?.current?.onMouseMove(e.touches.item(0).clientX, e.touches.item(0).clientY)}
                 onTouchEnd={() => props.cardTipRef?.current?.onMouseLeave()}
                 onMouseEnter={() => props.cardTipRef?.current?.onMouseEnter(data)}
                 onMouseMove={(e) => props.cardTipRef?.current?.onMouseMove(e.clientX, e.clientY)}
                 onMouseLeave={() => props.cardTipRef?.current?.onMouseLeave()}
            >
                <div className={'absolute rotate-90'} style={{
                    width: len(BUILDING_WIDTH),
                    height: len(BUILDING_HEIGHT),
                    fontSize: len(BUILDING_TEXT_SIZE),
                    top: len((WONDER_HEIGHT - BUILDING_HEIGHT) / 2),
                    right: isLeftPlayer ? len(0) : "unset",
                    left: isLeftPlayer ? "unset" : len(0),
                }}>
                    {createdBy &&
                        <BuildingView data={{ cardType: '建筑', name: createdBy, showBack: true, createBy: '' }}/>}
                </div>
                <div className={'relative w-full h-full'}>
                    {data && <WonderView data={data}/>}
                </div>
                <div/>
            </div>
        )
    }

    return <div className={'flex flex-col '}>
        <div className={'flex flex-row ' + (isLeftPlayer ? 'justify-start' : 'justify-end')}>
            {createOneWounder(wonders[0])}
            {createOneWounder(wonders[1])}
        </div>
        <div className={'h-1'}/>
        <div className={'flex flex-row ' + (isLeftPlayer ? 'justify-start' : 'justify-end')}>
            {createOneWounder(wonders[2])}
            {createOneWounder(wonders[3])}
        </div>
    </div>
}