import {CardRecord} from "../card";
import {ALL_BUILDING_DATA, ALL_WONDER_DATA} from "../GameInit";
import {arrayAdd} from "../utils";
import {BuildingFlag, ScienceFlag} from "./Building";
import {GameRoomRef} from "../container/game-room";

export declare type PlayerOwnedResource = {
    materials: number[], // 木头/石料/粘土/纸张/玻璃 数量
    factories: number[], // 木头/石料/粘土/纸张/玻璃 工厂数量，工厂建造一金币一个
    omnipotent: number[], // 木头/石料/粘土, 纸张/玻璃 替换材料数量
    flags: BuildingFlag[],
    science: ScienceFlag[],
}

export class Player {
    private builtBuildings = {
        '原材料': [],
        '加工材料': [],
        '商业': [],
        '市政': [],
        '科技': [],
        '行会': [],
        '军事': []
    }
    private wonders: (CardRecord | undefined)[] = []     // 玩家奇迹不同于建筑/发展标记，建筑/发展标记有就一定是玩家的，奇迹需要先建造才能算效果，所以这里用CardRecord
    private progressTokens: string[] = []
    private ownedGolds: number = 7
    private conflictLevel: number = 0                 // 冲突等级，正数表示己方占优势
    private oppositePlayer?: Player = undefined
    private showToastAbility?: (tip: string) => void = undefined

    public getBuiltBuildings() {
        return this.builtBuildings
    }

    public getWonders() {
        return this.wonders
    }

    public getProgressTokens() {
        return this.progressTokens
    }

    public getGolds() {
        return this.ownedGolds
    }

    public getConflictLevel() {
        return this.conflictLevel
    }

    // TODO(wangguichun): 2024/7/27 考虑删除
    public setConflictLevel(level: number) {
        this.conflictLevel = level
    }

    public removeGolds(num: number) {
        this.ownedGolds -= num
        this.ownedGolds = Math.max(0, this.ownedGolds)
    }

    // 购买 木头/石料/粘土/纸张/玻璃 价格
    private getBuyPriceOfMaterials(): number[] {
        let basePrice = [2, 2, 2, 2, 2]
        let oppositeMaterials = this.oppositePlayer?.getOwnedResource()?.materials;
        return arrayAdd(basePrice, oppositeMaterials ?? [0, 0, 0, 0, 0])
    }

    private calculateBuildCost(ownedSupperOmnipotent: number,
                               requireMaterials: number[],
                               requireFlag: BuildingFlag | undefined,
                               requireGolds: number): { totalCost: number, materialCost: number } {
        let ownedElements = this.getOwnedResource();
        if (requireFlag && ownedElements.flags.includes(requireFlag)) {
            // 白嫖标不用扣钱了, 如果有 ‘都市化’ 发展标记 还能加4金币
            return {
                totalCost: this.progressTokens.includes('都市化') ? -4 : 0,
                materialCost: 0
            }
        }

        let materialCost = 0
        let ownedOmnipotent = ownedElements.omnipotent.slice()

        // 1. 找出最贵的材料
        const sortedMaterialPrice = this.getBuyPriceOfMaterials().map((value, index) => {
            return { id: index, price: value }
        }).sort((a, b) => b.price - a.price)
        sortedMaterialPrice.forEach((value) => {
            const [id, price] = [value.id, value.price]
            let requiredNum = requireMaterials[id]
            // 2. 先用已有材料抵扣
            requiredNum -= ownedElements.materials[id]
            if (requiredNum <= 0) return
            // 3. 不够再用通用材料抵扣
            const omnipotentId = id <= 3 ? 0 : 1
            if (requiredNum > ownedOmnipotent[omnipotentId]) {
                requiredNum -= ownedOmnipotent[omnipotentId]
                ownedOmnipotent[omnipotentId] = 0
            } else {
                ownedOmnipotent[omnipotentId] -= requiredNum
                requiredNum = 0
            }
            if (requiredNum <= 0) return
            // 4. 不够再用超级万能材料抵扣
            if (requiredNum > ownedSupperOmnipotent) {
                requiredNum -= ownedSupperOmnipotent
                ownedSupperOmnipotent = 0
            } else {
                ownedSupperOmnipotent -= requiredNum
                requiredNum = 0
            }
            // 5. 不够再用工厂购买
            if (ownedElements.factories[id] > 0) {
                materialCost += requiredNum
                requiredNum = 0
            }
            // 6. 最后不够原价购买
            if (requiredNum <= 0) return
            materialCost += requiredNum * price
        })

        return { totalCost: requireGolds + materialCost, materialCost }
    }

    public getBuildingCost(buildingName: string): { totalCost: number, materialCost: number } {
        const building = ALL_BUILDING_DATA[buildingName]
        const ownedSupperOmnipotent = (building.getBuildingType() === '市政' && this.progressTokens.includes('钻石工厂')) ? 2 : 0
        return this.calculateBuildCost(ownedSupperOmnipotent, building.getRequireMaterials(), building.getRequireFlag(), building.getRequireGolds())
    }

    public addBuilding(buildingName: string) {
        const building = ALL_BUILDING_DATA[buildingName]
        const offerGolds = this.calculateOfferGoldsOrScores(building.getOfferGolds())
        this.ownedGolds += offerGolds
        // @ts-ignore
        this.builtBuildings[building.getBuildingType()].push(buildingName)
        this.attack(building.getOfferConflicts())
    }

    public buildBuilding(buildingName: string): boolean {
        const buildCost = this.getBuildingCost(buildingName)
        if (this.ownedGolds < buildCost.totalCost) {
            // 金币不够，不能建造
            this.showToast('金币不足')
            return false
        }
        this.ownedGolds -= buildCost.totalCost
        this.addBuilding(buildingName)

        if (this.oppositePlayer!!.progressTokens.includes('经济')) {
            this.oppositePlayer!!.ownedGolds += buildCost.materialCost
        }
        return true
    }

    public getSellerPrice() {
        if (this.builtBuildings['商业']) {
            return 2 + this.builtBuildings['商业'].length
        }
        return 2
    }

    public sellBuilding() {
        this.ownedGolds += this.getSellerPrice()
    }

    public removeBuilding(buildingName: string) {
        const buildingType = ALL_BUILDING_DATA[buildingName].getBuildingType()
        // @ts-ignore
        this.builtBuildings[buildingType] = this.builtBuildings[buildingType].filter((name) => name !== buildingName)
    }

    public recycleBuilding(buildingName: string) {
        this.addBuilding(buildingName)
    }

    public addWonder(wonder: CardRecord) {
        this.wonders.push(wonder);
    }

    public getWonderCost(wonderName: string): { totalCost: number, materialCost: number } {
        const wonder = ALL_WONDER_DATA[wonderName]
        const ownedSupperOmnipotent = this.progressTokens.includes('建筑') ? 2 : 0
        return this.calculateBuildCost(ownedSupperOmnipotent, wonder.getRequireMaterials(), undefined, 0)
    }

    public buildWonder(wonderName: string, createBy: string): boolean {
        let wonder = ALL_WONDER_DATA[wonderName];
        const wonderCost = this.getWonderCost(wonderName)
        if (this.ownedGolds < wonderCost.totalCost) {
            // 金币不够，不能建造
            this.showToast('金币不足')
            return false
        }
        this.ownedGolds -= wonderCost.totalCost
        this.wonders.forEach((value) => {
            if (value && value?.name === wonderName) {
                value.createBy = createBy
            }
        })
        if (this.progressTokens.includes('经济')) {
            this.oppositePlayer!!.ownedGolds += wonderCost.materialCost
        }
        let offerGolds = this.calculateOfferGoldsOrScores(wonder.getOfferGolds());
        this.ownedGolds += offerGolds
        this.attack(wonder.getOfferConflicts())
        // 各种效果的处理
        if (wonderName === '亚壁古道') {
            this.oppositePlayer!!.removeGolds(3)
        } else if (wonderName === '哈利卡纳斯得摩索拉斯陵墓') {
            this.showToast('可以从弃牌堆回收一张牌')
        } else if (wonderName === '奥林匹亚宙斯神像') {
            this.showToast('可以废弃对手一张原材料建筑')
        } else if (wonderName === '马克西姆斯竞技场') {
            this.showToast('可以废弃对手一张加工材料建筑')
        } else if (wonderName === '亚历山大图书馆') {
            this.showToast('选一个发展标记')
        }

        if (this.progressTokens.includes('宗教') || ['比雷埃夫斯港', '以弗所得亚底米神廊', '巴比伦空中花园', '狮身人面像斯芬克斯', '亚壁古道'].includes(wonderName)) {
            this.showToast('额外回合')
        }
        return true
    }

    public addProgressToken(progressTokenName: string) {
        if (progressTokenName === '农业') {
            this.ownedGolds += 6
        } else if (progressTokenName === '都市化') {
            this.ownedGolds += 6
        }
        this.progressTokens.push(progressTokenName)
    }

    // TODO(wangguichun): 2024/7/27 考虑删除
    public setGolds(golds: number) {
        this.ownedGolds = golds
    }

    public setOppositePlayer(player: Player): Player {
        this.oppositePlayer = player
        return this
    }

    public setShowToastAbility(fun?: (tip: string) => void) {
        this.showToastAbility = fun
    }

    public showToast(tip: string) {
        if (this.showToastAbility) {
            this.showToastAbility(tip)
        }
    }

    public static cloneFrom(obj: any): Player {
        return Object.assign(new Player(), obj)
    }

    public getOwnedResource(): PlayerOwnedResource {
        let materials: number[] = [0, 0, 0, 0, 0]
        let factories: number[] = [0, 0, 0, 0, 0]
        let omnipotent: number[] = [0, 0]
        let flags: BuildingFlag[] = []
        let science: ScienceFlag[] = []

        // 算建筑带来的资源
        Object.values(this.builtBuildings).forEach((buildings) => {
            buildings.forEach((buildingName) => {
                const building = ALL_BUILDING_DATA[buildingName]
                materials = arrayAdd(materials, building.getOfferMaterial())
                factories = arrayAdd(factories, building.getOfferFactory())
                omnipotent = arrayAdd(omnipotent, building.getOfferOmnipotent())
                if (building.getOfferFlag()) {
                    flags.push(building.getOfferFlag()!!)
                }
                if (building.getOfferScience()) {
                    science.push(building.getOfferScience()!!)
                }
            })
        })
        // 算奇迹带来的资源
        this.wonders.forEach((wonder) => {
            if (wonder && wonder.createBy) {
                const wonderData = ALL_WONDER_DATA[wonder.name]
                omnipotent = arrayAdd(omnipotent, wonderData.getOfferOmnipotent())
            }
        })

        return { materials, factories, omnipotent, flags, science }
    }

    public getOwnedScores(): { [key: string]: number } {
        // 计算所有商业建筑分数
        let score1 = 0
        this.builtBuildings['商业'].forEach((buildingName) => {
            const building = ALL_BUILDING_DATA[buildingName]
            score1 += this.calculateOfferGoldsOrScores(building.getOfferScores())
        })
        // 计算所有市政建筑分数
        let score2 = 0
        this.builtBuildings['市政'].forEach((buildingName) => {
            const building = ALL_BUILDING_DATA[buildingName]
            score2 += this.calculateOfferGoldsOrScores(building.getOfferScores())
        })
        // 计算所有科技建筑分数
        let score3 = 0
        this.builtBuildings['科技'].forEach((buildingName) => {
            const building = ALL_BUILDING_DATA[buildingName]
            score3 += this.calculateOfferGoldsOrScores(building.getOfferScores())
        })
        // 计算所有行会建筑分数
        let score4 = 0
        this.builtBuildings['行会'].forEach((buildingName) => {
            const building = ALL_BUILDING_DATA[buildingName]
            score4 += this.calculateOfferGoldsOrScores(building.getOfferScores())
        })
        // 计算所有奇迹建筑分数
        let score5 = 0
        this.wonders.filter((wonder) => wonder && wonder.createBy).forEach((wonder) => {
            const wonderData = ALL_WONDER_DATA[wonder!!.name]
            score5 += this.calculateOfferGoldsOrScores(wonderData.getOfferScores())
        })
        // 发展标记分数
        let score6 = 0
        this.progressTokens.forEach((token) => {
            if (token === '农业') {
                score6 += 4
            } else if (token === '数学') {
                score6 += this.progressTokens.length * 3
            } else if (token === '哲学') {
                score6 += 7
            }
        })
        // 战争分数
        let score7 = 0
        if (this.conflictLevel >= 6) {
            score7 = 10
        } else if (this.conflictLevel >= 3) {
            score7 = 5
        } else if (this.conflictLevel >= 1) {
            score7 = 2
        }
        // 金币分数
        let score8 = Math.floor(this.ownedGolds / 3)
        return {
            '商业建筑分数': score1,
            '市政建筑分数': score2,
            '科技建筑分数': score3,
            '行会建筑分数': score4,
            '奇迹建筑分数': score5,
            '发展标记分数': score6,
            '战争分数': score7,
            '金币分数': score8
        }
    }

    private calculateOfferGoldsOrScores(offer: number | { types: string[], num: number }): number {
        if (typeof offer === 'number') {
            return offer
        }
        let totalCnt = 0
        offer.types.forEach((type) => {
            if (Object.keys(this.builtBuildings).includes(type)) {
                // @ts-ignore
                const my = this.builtBuildings[type].length * offer.num
                // @ts-ignore
                const opposite = this.oppositePlayer!!.builtBuildings[type].length * offer.num
                totalCnt += Math.max(my, opposite)
            } else if (type === '奇迹') {
                const my = this.wonders.filter(value => value && value.createBy).length * offer.num
                const opposite = this.oppositePlayer!!.wonders.filter(value => value && value.createBy).length * offer.num
                totalCnt += Math.max(my, opposite)
            } else if (type === '(金币/3)') {
                const my = (Math.floor(this.ownedGolds / 3)) * offer.num
                const opposite = (Math.floor(this.oppositePlayer!!.ownedGolds / 3)) * offer.num
                totalCnt += Math.max(my, opposite)
            }
        })

        return totalCnt
    }

    private attack(level: number) {
        if (this.progressTokens.includes('战略')) {
            level += 1
        }
        this.conflictLevel += level
        this.oppositePlayer!!.conflictLevel -= level
    }
}