import {forwardRef, Ref, RefObject, useImperativeHandle, useRef, useState} from "react";
import {
    MSG_ACK,
    MSG_FROM_A,
    MSG_FROM_B,
    MSG_FROM_SYSTEM,
    MSG_GAME_START,
    MSG_PLAYER_JOIN, MSG_PLAYER_LEAVE, MSG_REQUEST_REMOVE_OTHERS, MSG_ROOM_CREATED, MSG_SYNC_ALL,
    webSocketPath
} from "../../constants";
import MessageRecordContainer from "./message-recorder";

export declare type GameRoomRef = {
    sendMsg: (msg: string, extra?: {}) => void
    showToast: (tip: string) => void
    addMsgListener: (key: string, listener: MsgListener) => void
}

export declare type SocketMessage = {
    from: string,
    text: string,
    extra: any,
}
export declare type MsgListener = (msg: SocketMessage) => void

const GameRoom = forwardRef((props: {
    started: boolean,
    getNeedSyncData: () => string,
    onReceiveSyncedData: (data: string, syncFromA: boolean) => void
    messageListener: MsgListener
    onGetIdentity: (isPlayerA: boolean) => void,
    startGame: () => void,
}, ref: Ref<GameRoomRef>) => {
    const [roomId, setRoomId] = useState("")
    // 是否完成数据初始化，创建房间的人会初始化数据，玩家2加入后，完成初始化数据的人会向其同步数据
    const [initComplete, setInitComplete] = useState(false)
    // 跟是否显示开始游戏按钮挂钩
    const [playerReady, setPlayerReady] = useState(false)
    // 中间区域的提示文案
    const [info, setInfo] = useState("")
    // 显示房间满员的提示
    const [showFullTip, setShowFullTip] = useState(false)
    // 是否是房主，playerA
    const [isHost, setIsHost] = useState<boolean | undefined>(undefined)
    const [showLoginPanel, setShowLoginPanel] = useState(true)
    const [showLoading, setShowLoading] = useState(false)
    const [tip, setTip] = useState('')

    const socket = useRef<WebSocket>()
    const msgListenersRef = useRef<{ [key: string]: MsgListener }>({})

    useImperativeHandle(ref, () => ({
        sendMsg: sendMsg,
        showToast: showToast,
        addMsgListener: addMsgListener,
    }))

    const sendMsg = (msg: string, extra?: {}, from?: string) => {
        const defaultFrom = isHost ? MSG_FROM_A : MSG_FROM_B
        socket.current?.send(JSON.stringify({
            from: from ?? defaultFrom,
            text: msg,
            extra: JSON.stringify(extra ?? {}),
        }));
        if (msg !== MSG_ACK && msg !== MSG_REQUEST_REMOVE_OTHERS) {
            setShowLoading(true)
        }
    }

    const showToast = (tip: string) => {
        setTip(tip)
        setTimeout(() => {
            setTip('')
        }, 2000)
    }

    const addMsgListener = (key: string, listener: MsgListener) => {
        if (msgListenersRef.current) {
            msgListenersRef.current[key] = listener
        }
    }

    const onSocketReceiveMsg = (event: MessageEvent) => {
        const msg = JSON.parse(event.data) as SocketMessage
        msg.extra = JSON.parse(msg.extra)
        console.log("收到消息", msg.from, msg.text, msg.extra)
        Object.keys(msgListenersRef.current || {}).forEach((key) => {
            const listener = msgListenersRef.current?.[key]
            listener?.(msg)
        })
    }

    const joinOrCreateRoom = () => {
        if (!roomId) return
        socket.current = new WebSocket(webSocketPath + roomId);
        socket.current.onmessage = onSocketReceiveMsg
        setShowFullTip(false)
        setShowLoading(true)
    }

    addMsgListener('root', props.messageListener)
    addMsgListener('game-room', (msg: SocketMessage) => {
        const msgIsFromHost = msg.from === MSG_FROM_A
        const msgFromSelf = msgIsFromHost === isHost
        const msgFromSystem = msg.from === MSG_FROM_SYSTEM
        if (msgFromSystem) {
            setShowLoading(false)
        } else if (!msgFromSelf && msg.text !== MSG_ACK) {
            sendMsg(MSG_ACK, {})
        }

        if (msg.text === MSG_ACK) {
            setShowLoading(false)
        } else if (msgFromSystem && msg.text === MSG_ROOM_CREATED) {
            setInfo(`${info}\n${msg.text}`)
            setIsHost(true)
            setInitComplete(true)
            props.onGetIdentity(true)
        } else if (msgFromSystem && msg.text.startsWith(MSG_PLAYER_JOIN)) {
            const currentPlayerCnt = msg.text.substring(MSG_PLAYER_JOIN.length)
            if (currentPlayerCnt === '1') {
                setInfo(`${info}\n${msg.text}，等待另一名玩家中...`)
            } else if (currentPlayerCnt === '2') {
                if (initComplete) {
                    sendMsg(MSG_SYNC_ALL, props.getNeedSyncData())
                }
                setInfo(`${info}\n${msg.text}, 开始游戏吧～`)
                setPlayerReady(true)
            } else {
                // 人数已满，退出房间
                if (!initComplete) {      // 加入的前两名玩家一定init过了，即便是退出重连后，也会init，没有init的就是新加入的
                    setShowFullTip(true)
                    socket.current?.close()
                }
            }
        } else if (msg.text === MSG_SYNC_ALL && !msgFromSelf) {
            const data = msg.extra
            setIsHost(msg.from !== MSG_FROM_A)
            setInitComplete(true)
            props.onGetIdentity(msg.from !== MSG_FROM_A)
            props.onReceiveSyncedData(data, msgIsFromHost)
        } else if (msg.text.startsWith(MSG_PLAYER_LEAVE)) {
            const currentPlayerCnt = msg.text.substring(MSG_PLAYER_LEAVE.length)
            if (currentPlayerCnt === '1') {
                setInfo(`${msg.text}，等待另一名玩家中...`)
                setPlayerReady(false)
                setShowLoginPanel(true)
            }
        } else if (msgFromSystem && msg.text === MSG_GAME_START) {
            props.startGame()
            setShowLoginPanel(false)
        }
    })

    const createLoginInitialPanel = () => <>
        <input placeholder={'输入房间号'} className={'text-2xl p-2 rounded-full text-center'}
               value={roomId}
               onChange={(e) =>
                   setRoomId(e.target.value)
               }/>
        {showFullTip && <p className={'p-1'}>房间已满，请重新输入房间号</p>}
        <button className={'mt-8 text-xl border-black border-2 rounded-full p-2'}
                style={{
                    opacity: roomId ? '1' : '0.5',
                }}
                onClick={() => joinOrCreateRoom()}>
            创建/加入
        </button>
    </>

    const createLoginWaitingPanel = () => <>
        <div className={'text-2xl p-2 rounded-full text-center'}>房间：{roomId}</div>
        <div style={{ whiteSpace: 'pre-wrap', fontSize: '14px' }}>
            {info}
        </div>
        {playerReady &&
            (
                isHost ? (
                    <button className={'mt-8 text-xl border-black border-2 rounded-full p-2'}
                            onClick={() => {
                                sendMsg(MSG_GAME_START, {}, MSG_FROM_SYSTEM)
                            }}>
                        {props.started ? '继续游戏' : '开始游戏'}
                    </button>
                ) : (
                    <>
                        <button
                            className={'mt-8 text-xl border-black border-2 rounded-full p-2 opacity-50'}>
                            等待开始
                        </button>
                    </>
                )
            )
        }
    </>

    const createLoadingView = () => (
        <div
            className={'fixed flex w-full h-full left-0 top-0 z-50 justify-center items-center'}>
            <div className={'bg-white text-black text-6xl border-2 border-black rounded-full p-4'}>
                Loading...
            </div>
        </div>
    )

    const createTipView = (tip: string) => (
        <div
            className={'fixed flex w-full h-full left-0 top-0 z-50 justify-center items-center'}>
            <div className={'bg-white text-black text-4xl border-2 border-black rounded-3xl p-8'}>
                {tip}
            </div>
        </div>
    )

    return (
        <>
            {showLoginPanel &&
                <div className={'fixed left-0 top-0 flex h-full w-full justify-center z-50 bg-black bg-opacity-40'}>
                    <div
                        className={'absolute p-8 w-96 top-80 flex flex-col items-center justify-center bg-blue-100 border-2 border-black rounded-3xl z-50'}>
                        {info ? createLoginWaitingPanel() : createLoginInitialPanel()}
                    </div>
                </div>}
            {showLoading && createLoadingView()}
            {tip && createTipView(tip)}
            <button className={'fixed bottom-0 right-20 z-50'} onClick={() => {
                sendMsg(MSG_REQUEST_REMOVE_OTHERS)
            }}>踢出其他人
            </button>
            <MessageRecordContainer gameRoomRef={ref as RefObject<GameRoomRef>}/>
        </>
    )
})

export default GameRoom
