import { readCSV } from "./utils";
import buildingCsvFile from "./assets/buildings.csv";
import wonderCsvFile from "./assets/wonders.csv";
import progressCsvFile from "./assets/progress_token.csv";
import { Building } from "./type/Building";
import { Wonder } from "./type/Wonder";
import { ProgressToken } from "./type/ProgressToken";

export let ALL_BUILDING_DATA: { [key: string]: Building } = {}
export let ALL_WONDER_DATA: { [key: string]: Wonder } = {}
export let ALL_PROGRESS_DATA: { [key: string]: ProgressToken } = {}

export const GAME_INIT = (callback?: () => void) => {
    let completeCnt = 0
    const checkReturn = () => {
        completeCnt++
        if (completeCnt === 3) {
            if (callback) {
                callback()
            }
        }
    }

    readCSV(buildingCsvFile, d => {
        d.map(v => new Building(v)).forEach(building => {
            ALL_BUILDING_DATA[building.getName()] = building
        })
        checkReturn()
    })
    readCSV(wonderCsvFile, d => {
        d.map(v => new Wonder(v)).forEach(wonder => {
            ALL_WONDER_DATA[wonder.getName()] = wonder
        })
        checkReturn()
    })
    readCSV(progressCsvFile, d => {
        d.map(v => new ProgressToken(v)).forEach(progress => {
            ALL_PROGRESS_DATA[progress.getName()] = progress
            checkReturn()
        })
    })
}


