import WonderView from "../../card/wonder-view";
import {
    len,
    MSG_WONDER_DISPATCHER_PHASE_0,
    MSG_WONDER_DISPATCHER_PHASE_X,
    WONDER_HEIGHT,
    WONDER_TEXT_SIZE,
    WONDER_WIDTH
} from "../../constants";
import { RefObject, useEffect, useState } from "react";
import { prepareWonders } from "../../utils";
import { GameRoomRef, SocketMessage } from "../game-room";

const WonderDispatcher = (props: {
    gameRoomRef: RefObject<GameRoomRef>,
    iAmPlayerA: boolean,
    firstPlayerIsA: boolean,
    onComplete: () => void,
}) => {
    const [first4Wonders, setFirst4Wonders] = useState<(string | undefined)[]>([])
    const [second4Wonders, setSecond4Wonders] = useState<(string | undefined)[]>([])
    // 0: 空白，点击开始； 1-8 分别为点击第N个卡片的阶段
    const [phase, setPhase] = useState(0)
    const current4Wonders = (phase <= 4) ? first4Wonders : second4Wonders

    const gameRoom = props.gameRoomRef?.current
    gameRoom?.addMsgListener('wonder-dispatcher', (msg: SocketMessage) => {
        // MSG_WONDER_DISPATCHER_PHASE_0 已经是最早的时机了，再早，wonder-dispatcher还没创建
        if (msg.text === MSG_WONDER_DISPATCHER_PHASE_0) {
            if (!props.iAmPlayerA) {
                setFirst4Wonders(msg.extra.first4Wonders)
                setSecond4Wonders(msg.extra.second4Wonders)
            }
            setPhase(1)
        } else if (msg.text === MSG_WONDER_DISPATCHER_PHASE_X) {
            const wonderName = msg.extra
            const newState = current4Wonders.map((value) => {
                if (value === wonderName) {
                    return undefined
                } else {
                    return value
                }
            });
            (phase <= 4) ? setFirst4Wonders(newState) : setSecond4Wonders(newState)
            if (phase === 8) {
                props.onComplete()
            }
            setPhase(phase + 1)
        }
    })

    useEffect(() => {
        const targetWonders = prepareWonders()
        setFirst4Wonders(targetWonders.slice(0, 4))
        setSecond4Wonders(targetWonders.slice(4, 8))
    }, []);

    const doWonderClick = (name: string | undefined) => {
        if (!name) return
        const firstPlayerPhases = [1, 4, 6, 7]
        const isFirstPlayerPhase = firstPlayerPhases.indexOf(phase) >= 0
        const iAmFirstPlayer = props.firstPlayerIsA === props.iAmPlayerA
        if (iAmFirstPlayer === isFirstPlayerPhase) {
            gameRoom?.sendMsg(MSG_WONDER_DISPATCHER_PHASE_X, name)
        }
    }

    let mainContent = <></>
    if (phase === 0) {
        mainContent = (
            props.iAmPlayerA ?
                <button className={'text-3xl'}
                        onClick={() => {
                            gameRoom?.sendMsg(MSG_WONDER_DISPATCHER_PHASE_0, { first4Wonders, second4Wonders })
                        }}>
                    点击分发奇迹
                </button>
                :
                <button className={'text-3xl opacity-50'}>等待分发奇迹</button>
        )
    } else if (phase <= 8) {
        mainContent = (<>
                {current4Wonders.map((value, index) =>
                    <div
                        key={index}
                        style={{
                            margin: len(8),
                            height: len(1.2 * WONDER_HEIGHT),
                            width: len(1.2 * WONDER_WIDTH),
                            fontSize: len(1.2 * WONDER_TEXT_SIZE),
                        }}>
                        {value && <div className={'border-black border-2 rounded-lg w-full h-full'}
                                       onClick={() => doWonderClick(value)}>
                            <WonderView data={{ name: value, cardType: '奇迹', createBy: '', showBack: false }}/>
                        </div>}
                    </div>
                )}</>
        )
    }

    return (
        <div className={'fixed top-0 left-0 flex h-full w-full justify-center z-40 bg-black bg-opacity-40'}>
            <div
                className={'absolute w-96 top-80 flex flex-wrap flex-row items-center justify-center bg-blue-100 border-2 border-black rounded-3xl p-2 z-40'}
                style={{
                    width: len(3 * WONDER_WIDTH),
                    height: len(3 * WONDER_HEIGHT)
                }}>
                {mainContent}
            </div>
        </div>
    )

}

export default WonderDispatcher