import GameDesk from "./container/game-desk";
import { len } from "./constants";

export function App() {
    return (
        <div className={'absolute w-full'} style={{ minWidth: len(524) }}>
            <GameDesk/>
        </div>
    )
}
