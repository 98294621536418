import React, {
    ForwardedRef,
    forwardRef,
    RefObject,
    useImperativeHandle,
    useRef,
    useState
} from 'react';
import {
    BUILDING_HEIGHT,
    BUILDING_TEXT_SIZE,
    BUILDING_WIDTH,
    len, MSG_CHANGE_PLAYER_PHASE,
    MSG_CLICK_DRAFT_BUILDING,
    MSG_CLICK_DRAFT_BUILDING_RETURN,
    MSG_DRAFT_POOL_PHASE_1,
    MSG_DRAFT_POOL_PHASE_2,
    MSG_DRAFT_POOL_PHASE_3, MSG_DRAFT_POOL_PHASE_4, MSG_FROM_A,
    MSG_SYNC_SELECTED_CARDS
} from "../../constants";
import BuildingView from "../../card/building-view";
import {prepareBuildingDataListByTimes} from "../../utils";
import {CardTipRef} from "../temper-contaniers/card-tip";
import {GameRoomRef, SocketMessage} from "../game-room";
import ActionContainer, {
    ActionContainerRef,
    NormalUserAction,
    SpecialUserAction,
} from "../action-container";
import {CardRecord} from "../../card";

const BUILDING_MARGIN = 7
const poolShape = [ // 1 表示正常放牌，2表示反面，0不放牌
    [
        [1, 1],
        [2, 2, 2],
        [1, 1, 1, 1],
        [2, 2, 2, 2, 2],
        [1, 1, 1, 1, 1, 1],
    ], [
        [1, 1, 1, 1, 1, 1],
        [0, 2, 2, 2, 2, 2],
        [0, 0, 1, 1, 1, 1, 0],
        [0, 0, 0, 2, 2, 2],
        [0, 0, 0, 0, 1, 1, 0],
    ], [
        [1, 1, 0, 0, 0, 0],
        [2, 2, 2, 0, 0, 0],
        [1, 1, 1, 1, 0, 0],
        [0, 2, 0, 2, 0, 0],
        [0, 1, 1, 1, 1, 0],
        [0, 0, 2, 2, 2, 0],
        [0, 0, 0, 1, 1, 0],
    ],
]

const getGameCardGroupByTimes = (times: number, buildingDataList: (string | undefined)[]): (CardRecord | undefined)[][] => {
    if (buildingDataList.length === 0 || times < 1 || times > 3) return []
    const result: (CardRecord | undefined)[][] = []
    const shape = poolShape[times - 1]
    let i = 0
    for (let row = 0; row < shape.length; row++) {
        const line: (CardRecord | undefined)[] = []
        for (let col = 0; col < shape[row].length; col++) {
            const value = shape[row][col]
            if (value === 0) {
                line.push(undefined)
            } else {
                const name = buildingDataList[i++]
                if (name) {
                    const data: CardRecord = { cardType: '建筑', createBy: '', name, showBack: value === 2 }
                    line.push(data)
                } else {
                    line.push(undefined)
                }
            }

            // 添加进去的 data 为 undefined 时，如果左边一张卡也是undefined，要将上层的卡片时处于背面时，要改回正面
            if (!line[col] && !line[col - 1]) {
                let lastRow = result[row - 1];
                let targetData = lastRow?.[col - 1];
                if (targetData) {
                    targetData.showBack = false
                }
            }
        }
        result.push(line)
    }
    return result
}

// 从卡牌池角度检查卡片是否能被点击，主要看是否被其他卡片覆盖
const canCardRemove = (building: CardRecord, buildingStructure: (CardRecord | undefined)[][]): boolean => {
    for (let i = 0; i < buildingStructure.length; i++) {
        for (let j = 0; j < buildingStructure[i].length; j++) {
            if (buildingStructure[i][j]?.name === building.name) {
                return buildingStructure[i + 1] === undefined || (buildingStructure[i + 1][j] === undefined && buildingStructure[i + 1][j + 1] === undefined);
            }
        }
    }
    return false
}

export type DraftPoolData = { times: number, draftPoolCards: (string | undefined)[] }

export type DraftPoolRef = {
    getWaitedAction: () => SpecialUserAction | undefined
    getSelectedBuilding: () => string | undefined
    getNeedSyncData: () => DraftPoolData
    onReceiveSyncData: (data: DraftPoolData) => void
}

export const DraftPoolContainer = forwardRef((props: {
    cardTipRef: RefObject<CardTipRef>,
    gameRoomRef: RefObject<GameRoomRef>,
    iAmPlayerA: boolean,
    isMyPhase: boolean,
    onGameStart: () => void,
    onGameOver: () => void,
}, ref: ForwardedRef<DraftPoolRef>) => {
    const [draftPoolCards, setDraftPoolCards] = useState<(string | undefined)[]>([])
    const [times, setTimes] = useState<number>(0)
    const [selectedCard, setSelectedCard] = useState<string>()

    const buildingDataStructure = getGameCardGroupByTimes(times, draftPoolCards)
    const cardWidthWithMargin = BUILDING_WIDTH + 2 * BUILDING_MARGIN
    const cardHeightWithMargin = BUILDING_HEIGHT + 2 * BUILDING_MARGIN
    const isEmpty = draftPoolCards.filter((v) => v).length === 0
    const actionViewRef = useRef<ActionContainerRef>(null)

    useImperativeHandle(ref, () => ({
        getWaitedAction: () => actionViewRef.current?.getWaitedAction,
        getSelectedBuilding: () => selectedCard,
        getNeedSyncData: () => {
            return { times, draftPoolCards }
        },
        onReceiveSyncData: (data: DraftPoolData) => {
            const { times, draftPoolCards } = data
            setTimes(times)
            setDraftPoolCards(draftPoolCards)
        }
    }))

    const startTimes = () => {
        let gameRoom = props.gameRoomRef?.current;
        if (props.iAmPlayerA) {
            if (times === 0) {
                gameRoom?.sendMsg(MSG_DRAFT_POOL_PHASE_1, prepareBuildingDataListByTimes(1))
            } else if (times === 1) {
                gameRoom?.sendMsg(MSG_DRAFT_POOL_PHASE_2, prepareBuildingDataListByTimes(2))
            } else if (times === 2) {
                gameRoom?.sendMsg(MSG_DRAFT_POOL_PHASE_3, prepareBuildingDataListByTimes(3))
            } else {
                gameRoom?.sendMsg(MSG_DRAFT_POOL_PHASE_4)
            }
        }
    }

    const resetStatus = () => {
        actionViewRef.current?.hide()
        if (selectedCard) {
            setSelectedCard(undefined)
            props.gameRoomRef?.current?.sendMsg(MSG_SYNC_SELECTED_CARDS, '')
        }
    }

    const createOneCard = (data: CardRecord | undefined, i: number, j: number) => {
        if (!data) return <></>

        const extraTop = (times < 3) ? BUILDING_HEIGHT / 2 : 0
        const startLeftNum = (times === 2) ? 0 : 4
        const top = cardHeightWithMargin * 0.5 * i + extraTop
        const left = cardWidthWithMargin * 0.5 * (startLeftNum - i) + cardWidthWithMargin * j
        const isSelectedCard = data.name === selectedCard

        return (
            <div className={'absolute w-full h-full'}
                 key={i * 10 + j}
                 style={{
                     height: len(BUILDING_HEIGHT),
                     width: len(BUILDING_WIDTH),
                     fontSize: len(BUILDING_TEXT_SIZE),
                     margin: len(BUILDING_MARGIN),
                     top: len(top),
                     left: len(left),
                     opacity: isSelectedCard ? 0.7 : 1,
                 }}
                 onClick={(e) => {
                     if (props.isMyPhase && canCardRemove(data, buildingDataStructure)) {
                         setSelectedCard(data?.name)
                         props.gameRoomRef?.current?.sendMsg(MSG_SYNC_SELECTED_CARDS, data?.name)
                         props.cardTipRef?.current?.onMouseLeave()
                         actionViewRef?.current?.show(e.clientX - 20, e.clientY - 20)
                         e.stopPropagation()
                     }
                 }}
                 onTouchStart={() => props.cardTipRef?.current?.onMouseEnter(data)}
                 onTouchMove={(e) => props.cardTipRef?.current?.onMouseMove(e.touches.item(0).clientX, e.touches.item(0).clientY)}
                 onTouchEnd={() => props.cardTipRef?.current?.onMouseLeave()}
                 onMouseEnter={() => props.cardTipRef?.current?.onMouseEnter(data)}
                 onMouseMove={(e) => props.cardTipRef?.current?.onMouseMove(e.clientX, e.clientY)}
                 onMouseLeave={() => props.cardTipRef?.current?.onMouseLeave()}
            >
                <BuildingView data={data}/>
            </div>
        )
    }

    // 处理各种监听到的事件
    props.gameRoomRef?.current?.addMsgListener('draftpool-container', (msg: SocketMessage) => {
        if (msg.text === MSG_DRAFT_POOL_PHASE_1) {
            props.onGameStart()
            setTimes(1)
            setDraftPoolCards(msg.extra)
        } else if (msg.text === MSG_DRAFT_POOL_PHASE_2) {
            setTimes(2)
            setDraftPoolCards(msg.extra)
        } else if (msg.text === MSG_DRAFT_POOL_PHASE_3) {
            setTimes(3)
            setDraftPoolCards(msg.extra)
        } else if (msg.text === MSG_DRAFT_POOL_PHASE_4) {
            props.onGameOver()
            setTimes(4)
        } else if (msg.text === MSG_SYNC_SELECTED_CARDS) {
            if (props.iAmPlayerA !== (msg.from === MSG_FROM_A)) {
                setSelectedCard(msg.extra)
            }
        } else if (msg.text === MSG_CLICK_DRAFT_BUILDING_RETURN) {
            const { result } = msg.extra
            // 返回值代表了外部各种校验和计算成功，这里直接移除卡片
            if (result) {
                // 移除draftPoolCards中的building
                setDraftPoolCards(draftPoolCards.map((value) => {
                    if (value === selectedCard) {
                        return undefined
                    }
                    return value
                }))
            }
        } else if (msg.text === MSG_CHANGE_PLAYER_PHASE) {
            resetStatus()
        }
    })

    const createEmptyArea = () => {
        const isHost = props.iAmPlayerA
        if (times < 3) {
            return isHost ? (<button className={'flex text-6xl'} onClick={() => startTimes()}>点击发牌</button>) :
                (<span className={'flex text-6xl'}>等待发牌</span>)
        } else {
            return isHost ? (
                    <button className={'flex text-6xl'} onClick={() => startTimes()}>确认结束，进入结算</button>) :
                (<span className={'flex text-6xl'}>等待结算</span>)
        }
    }

    return (
        <div className={'flex flex-grow h-0 items-center justify-center z-10'}
             style={{ width: len(cardWidthWithMargin * 6), minHeight: len(cardHeightWithMargin * 4) }}
             onClick={() => {
                 if (props.isMyPhase) {
                     resetStatus()
                 }
             }}
        >
            {isEmpty ? createEmptyArea() :
                <div className={'relative flex w-full'}
                     style={{ height: len(cardHeightWithMargin * 4) }}
                >
                    {buildingDataStructure.map((rowData, i) =>
                        rowData.map((data, j) =>
                            createOneCard(data, i, j)
                        ))
                    }
                    <ActionContainer
                        ref={actionViewRef}
                        onActionClick={(action: NormalUserAction) => {
                            props.gameRoomRef?.current?.sendMsg(MSG_CLICK_DRAFT_BUILDING, {
                                action,
                                name: selectedCard
                            })
                        }}
                        onCancel={() => {
                            resetStatus()
                        }}
                    />
                </div>
            }
        </div>
    )
})

export default DraftPoolContainer