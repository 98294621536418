import {forwardRef, Ref, useImperativeHandle, useState} from "react";
import {len} from "../../constants";

export declare type NormalUserAction = '建造奇迹' | '建造建筑' | '丢弃换金币' | '通用'
export declare type SpecialUserAction = '等待建造奇迹'
export declare type UserAction = NormalUserAction | SpecialUserAction

export declare type ActionContainerRef = {
    show: (clientX: number, clientY: number) => void
    hide: () => void
    getWaitedAction: SpecialUserAction | undefined
    clearWaitedAction: () => void
}

const ActionContainer = forwardRef((props: {
    onActionClick: (action: NormalUserAction) => void
    onCancel: () => void
}, ref: Ref<ActionContainerRef>) => {
    const { onActionClick } = props
    const actionSort: NormalUserAction[] = ['建造建筑', '建造奇迹', '丢弃换金币']
    const [show, setShow] = useState(false)
    const [pos, setPos] = useState<{ x: number, y: number }>({ x: 0, y: 0 })
    const [waitedAction, setWaitedAction] = useState<SpecialUserAction>()

    useImperativeHandle(ref, () => ({
        show: (clientX: number, clientY: number) => {
            setShow(true)
            setPos({ x: clientX, y: clientY })
        },
        hide: () => {
            setShow(false)
        },
        getWaitedAction: waitedAction,
        clearWaitedAction: () => setWaitedAction(undefined)
    }))

    return show ?
        <div className={'fixed flex flex-col w-32 bg-gray-400 rounded-lg border-2 border-black'}
             style={{ top: len(pos.y), left: len(pos.x) }}
             onMouseLeave={() => props.onCancel()}
        >
            {(actionSort).map((action, index) =>
                <button key={index}
                        className={'flex flex-grow h-8 m-1 bg-pink-300 items-center justify-center rounded-lg'}
                        onClick={(e) => {
                            setWaitedAction(undefined)
                            if (action === '建造奇迹') {
                                setWaitedAction('等待建造奇迹')
                            }
                            setShow(false)
                            onActionClick(action)
                            e.stopPropagation()
                        }}>
                    {action}
                </button>
            )}
        </div>
        : <></>
})

export default ActionContainer