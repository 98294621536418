import {RefObject, useState} from "react";
import {getCurrentTimeString} from "../../utils";
import {GameRoomRef, SocketMessage} from "../game-room";
import {
    MSG_ACK,
    MSG_BUILD_WONDER,
    MSG_CHANGE_GOLD,
    MSG_CLICK_BUILT_BUILDING,
    MSG_CLICK_DISCARDED_POOL_BUILDING,
    MSG_CLICK_DRAFT_BUILDING,
    MSG_CLICK_DRAFT_BUILDING_RETURN,
    MSG_CLICK_PROGRESS_TOKEN,
    MSG_FLIP_RESULT, MSG_SAVED,
    MSG_SYNC_ALL,
    MSG_SYNC_SELECTED_CARDS,
    MSG_WONDER_DISPATCHER_PHASE_0,
    MSG_WONDER_DISPATCHER_PHASE_X
} from "../../constants";

export const MessageRecordContainer = (props: {
    gameRoomRef: RefObject<GameRoomRef>
}) => {
    const [visible, setVisible] = useState(false)
    const [socketMsg, setSocketMsg] = useState("")

    const buildMsg = (msg: SocketMessage): string => {
        if ([
            MSG_ACK,
            MSG_SYNC_SELECTED_CARDS,
            MSG_BUILD_WONDER,
            MSG_CLICK_DRAFT_BUILDING
        ].includes(msg.text)) {
            return socketMsg
        }

        let content = ''
        switch (msg.text) {
            case MSG_FLIP_RESULT:
                content = `${msg.text} - 【${msg.extra}】先行`
                break
            case MSG_WONDER_DISPATCHER_PHASE_X:
                content = `${msg.text}【${msg.extra}】`
                break
            case MSG_CLICK_DRAFT_BUILDING_RETURN:
                if (msg.extra.result) {
                    content = msg.extra.tip;
                }
                break
            case MSG_CLICK_BUILT_BUILDING:
                content = `销毁了${msg.extra.belongPlayerA ? 'PlayerA' : 'PlayerB'}的建筑【${msg.extra.data}】`
                break
            case MSG_CLICK_DISCARDED_POOL_BUILDING:
                content = `回收了建筑【${msg.extra}】`
                break
            case MSG_CLICK_PROGRESS_TOKEN:
                content = `选择了发展标记【${msg.extra}】`
                break
            case MSG_CHANGE_GOLD:
                content = `变更金币到【${msg.extra.toNum}】`
                break
            case MSG_SAVED:
                content = `记录了游戏状态，当前第【${msg.extra.step}】回合，${msg.extra.tip}`
                break
            default:
                content = msg.text
        }

        if (content) {
            return `${getCurrentTimeString()}: [${msg.from}] - ${content}\n${socketMsg}`
        } else {
            return socketMsg
        }
    }

    const gameRoom = (props.gameRoomRef)?.current
    gameRoom?.addMsgListener('message-record-container', (msg: SocketMessage) => {
        setSocketMsg(buildMsg(msg))
    })

    return visible ? (
        <div className={'fixed flex flex-col w-full h-full top-0 bg-black bg-opacity-80 z-50'}
             style={{
                 whiteSpace: 'pre-wrap',
                 overflowY: 'auto'
             }}
        >
            <button className={'fixed text-white bottom-0 right-0'}
                    onClick={() => setVisible(false)}>关闭
            </button>
            <p className={'flex w-full px-8 text-white'}>
                {socketMsg}
            </p>
        </div>
    ) : (
        <button className={'fixed bottom-0 right-0 z-50'} onClick={() => setVisible(true)}>展示日志</button>
    )
}

export default MessageRecordContainer
