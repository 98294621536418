// 投硬币猜先后的控件
import { RefObject, useState } from "react";
import { GameRoomRef, SocketMessage } from "../game-room";
import { MSG_FLIP_CONFIRM, MSG_FLIP_READY, MSG_FLIP_RESULT, MSG_FROM_A, MSG_FROM_B } from "../../constants";

export const FlipCoin = (props: {
    gameRoomRef: RefObject<GameRoomRef>,
    iAmPlayerA: boolean,
    onClickOk: () => void,
}) => {

    const [text, setText] = useState("点击下面的按钮猜拳")
    const [iReady, setIReady] = useState(false)
    const [responded, setResponded] = useState(false)
    const [readyNum, setReadyNum] = useState(0)

    const gameRoom = (props.gameRoomRef)?.current
    gameRoom?.addMsgListener('flip-coin', (msg: SocketMessage) => {
        if (msg.text === MSG_FLIP_RESULT) {
            const winner = msg.extra
            setText("先手玩家为：" + winner)
            setResponded(true)
        } else if (msg.text === MSG_FLIP_CONFIRM) {
            props.onClickOk()
        } else if (msg.text === MSG_FLIP_READY) {
            setReadyNum(readyNum + 1)
            if (readyNum === 2 && props.iAmPlayerA) {
                const winder = Math.random() > 0.5 ? MSG_FROM_A : MSG_FROM_B
                gameRoom?.sendMsg(MSG_FLIP_RESULT, winder)
            }
        }
    })

    const onReadyClick = () => {
        gameRoom?.sendMsg(MSG_FLIP_READY)
        setIReady(true)
        setReadyNum(readyNum + 1)
        setText("等待结果中")
    }

    return (
        <div className={'fixed top-0 left-0 flex h-full w-full justify-center z-40 bg-black bg-opacity-40'}>
            <div
                className={'absolute p-8 w-96 top-80 flex flex-wrap flex-col items-center justify-center bg-blue-100 border-2 border-black rounded-3xl z-40'}>
                <p className={'text-2xl'}>{text}</p>
                {iReady || (
                    <button className={'mt-8 text-xl border-black border-2 rounded-full p-2'}
                            onClick={() => onReadyClick()}
                    >猜拳</button>
                )}
                {responded && props.iAmPlayerA && (
                    <button className={'mt-8 text-xl border-black border-2 rounded-full p-2'}
                            onClick={() => gameRoom?.sendMsg(MSG_FLIP_CONFIRM)}
                    >好的</button>
                )}
            </div>
        </div>
    )
}

export default FlipCoin
