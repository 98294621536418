import progressTokenImgFile from "../../assets/progress_tokens_v3_2X.webp";
import React from "react";
import { CardRecord } from "../index";
import { ALL_PROGRESS_DATA } from "../../GameInit";

export const ProgressTokenView = (props: { data: CardRecord }) => {

    const { name, showBack } = props.data
    const [locRow, locCol ] = ALL_PROGRESS_DATA[name].getLoc()
    const x = showBack ? 3 : locCol
    const y = showBack ? 3 : locRow

    return (
        <div className={'w-full h-full rounded-full flex shadow-xl'}
             style={{
                 backgroundImage: `url(${progressTokenImgFile})`,
                 backgroundSize: '400% 400%',
                 backgroundPositionX: `-${x}00%`,
                 backgroundPositionY: `-${y}00%`,
             }}>
        </div>
    )
}

export default ProgressTokenView