import React, { JSX } from "react";
import buildingsImgFile from '../../assets/buildings_v3_2X.webp'
import { CardRecord } from "../index";
import { ALL_BUILDING_DATA } from "../../GameInit";

function getImgXY(row: number, col: number, times: number, showBack: boolean) {
    let x = col
    let y = row
    if (showBack) {
        if (times === 1) {
            x = 10
            y = 6
        } else if (times === 2) {
            x = 11
            y = 6
        } else if (times === 3) {
            x = 0
            y = 7
        } else if (times === 4) {
            x = 1
            y = 7
        }
    }
    return { x, y }
}

export default function BuildingView(props: { data: CardRecord }): JSX.Element {
    const { name, showBack } = props.data
    const building = ALL_BUILDING_DATA[name]
    const [locRow, locCol] = building.getLoc()
    const times = building.getTimes()
    const { x, y } = getImgXY(locRow, locCol, times, showBack)

    return (
        <div className={'w-full h-full border-gray-600 rounded-lg flex justify-center items-end'}
             style={{
                 borderWidth: '2px',
                 backgroundImage: `url(${buildingsImgFile})`,
                 backgroundSize: '1200% 800%',
                 // backgroundRepeat: 'no-repeat',
                 // backgroundPositionX: `-${x * (BUILDING_WIDTH - 4)}px`,
                 // backgroundPositionY: `-${y * (BUILDING_HEIGHT - 4)}px`,
                 backgroundPositionX: `-${x}00%`,
                 backgroundPositionY: `-${y}00%`,
             }}>
            {showBack || <span className={'w-5/6 text-center bg-white rounded-lg opacity-100'}
                               style={{
                                   fontSize: '1em',
                                   marginBottom: '0.5em'
                               }}>{name}</span>}
        </div>
    )
}


