import {parseOfferGolds, parseOfferMaterialStr, parseOfferScores} from "../utils";

export declare type BuildingType = '军事' | '加工材料' | '原材料' | '商业' | '市政' | '科技' | '背景' | '行会'
export declare type BuildingFlag =
    '书'
    | '剑'
    | '堡垒'
    | '大厅'
    | '太阳'
    | '头盔'
    | '月亮'
    | '木桶'
    | '水滴'
    | '竖琴'
    | '酒壶'
    | '陶罐'
    | '雕像'
    | '面具'
    | '靶子'
    | '马蹄铁'
    | '齿轮'
export declare type ScienceFlag = '房间' | '日晷' | '望远镜' | '研磨钵' | '科学标记' | '笔墨' | '车轮'

export class Building {
    private readonly name = ''
    private readonly buildingType: BuildingType = '原材料'
    private readonly times: number = 0
    private readonly locRow: number = 0
    private readonly locCol: number = 0
    private readonly requireGolds: number = 0
    private readonly requireMaterials = [0, 0, 0, 0, 0]    // 木头/石料/粘土/纸张/玻璃 数量
    private readonly requireFlag: BuildingFlag | undefined = undefined
    private readonly offerMaterial = [0, 0, 0, 0, 0]       // 木头/石料/粘土/纸张/玻璃 数量
    private readonly offerFactory = [0, 0, 0, 0, 0]        // 木头/石料/粘土/纸张/玻璃 工厂数量，工厂建造一金币一个
    private readonly offerOmnipotent = [0, 0]     // 木头/石料/粘土, 纸张/玻璃 替换材料数量
    private readonly offerScores: number | { types: string[], num: number } = 0
    private readonly offerConflicts: number = 0
    private readonly offerScience: ScienceFlag | undefined = undefined
    private readonly offerFlag: BuildingFlag | undefined = undefined
    private readonly offerGolds: number | { types: string[], num: number } = 0

    constructor(obj: any) {
        this.name = obj["名称"] ?? "错误"
        this.buildingType = obj["建筑类型"] ?? ""
        this.times = Number(obj["时代"] ?? 0)
        this.locRow = Number(obj["行"] ?? 0)
        this.locCol = Number(obj["列"] ?? 0)
        this.offerConflicts = Number(obj["战争"] ?? "")
        this.offerFlag = obj["提供联动标记"] ?? ""
        this.offerGolds = parseOfferGolds(obj["提供金币"] ?? "")
        this.offerMaterial = parseOfferMaterialStr(obj["原材料"] ?? "").materials
        this.offerFactory = parseOfferMaterialStr(obj["原材料"] ?? "").factories
        this.offerOmnipotent = parseOfferMaterialStr(obj["原材料"] ?? "").omnipotent
        this.offerScience = obj["科学标记"] ?? ""
        this.offerScores = parseOfferScores(obj["积分"] ?? "")
        this.requireFlag = obj["需要联动标记"] ?? ""
        this.requireGolds = Number(obj["需要金币"] ?? 0)
        this.requireMaterials = [Number(obj["木头"] ?? 0), Number(obj["石料"] ?? 0), Number(obj["粘土"] ?? 0), Number(obj["纸张"] ?? 0), Number(obj["玻璃"] ?? 0)]
    }


    // 所有成员变量的get方法
    public getName() {
        return this.name
    }

    public getBuildingType() {
        return this.buildingType
    }

    public getTimes() {
        return this.times
    }

    public getLoc() {
        return [this.locRow, this.locCol]
    }

    public getRequireGolds() {
        return this.requireGolds
    }

    public getRequireMaterials() {
        return this.requireMaterials
    }

    public getRequireFlag() {
        return this.requireFlag
    }

    public getOfferMaterial() {
        return this.offerMaterial
    }

    public getOfferFactory() {
        return this.offerFactory
    }

    public getOfferOmnipotent() {
        return this.offerOmnipotent
    }

    public getOfferScores() {
        return this.offerScores
    }

    public getOfferConflicts() {
        return this.offerConflicts
    }

    public getOfferScience() {
        return this.offerScience
    }

    public getOfferFlag() {
        return this.offerFlag
    }

    public getOfferGolds() {
        return this.offerGolds
    }
}