import {BUILDING_HEIGHT, BUILDING_TEXT_SIZE, BUILDING_WIDTH, len, MSG_CLICK_BUILT_BUILDING} from "../../constants";
import BuildingView from "../../card/building-view";
import {CardTipRef} from "../temper-contaniers/card-tip";
import React, {RefObject} from "react";
import {GameRoomRef} from "../game-room";
import {CardRecord} from "../../card";


const classifyBuildings = (buildings: { [key: string]: string[] }): { [key: string]: string[] } => {
    return {
        '材料': (buildings['原材料'] || []).concat(buildings['加工材料'] || []),
        '商业': buildings['商业'] || [],
        '军事': buildings['军事'] || [],
        '科技': buildings['科技'] || [],
        '市政/行会': (buildings['市政'] || []).concat(buildings['行会'] || []),
    }
}

export default function PlayerBuildingsContainer(props: {
    isLeftPlayer: boolean,
    isMyPhase: boolean,
    buildings: { [key: string]: string[] }, // 按照建筑类型分类存放
    cardTipRef: RefObject<CardTipRef>,
    gameRoomRef: RefObject<GameRoomRef>
}) {
    const { isLeftPlayer, isMyPhase, buildings, gameRoomRef } = props

    const createOneBuilding = (name: string) => {
        const buildHeight = BUILDING_HEIGHT * 0.2
        const cardRecord: CardRecord = {
            name: name,
            cardType: '建筑',
            showBack: false,
            createBy: ''
        }

        return (
            <div className={'flex m-px'}
                 key={name}
                 style={{
                     width: len(BUILDING_WIDTH),
                     height: buildHeight,
                 }}>
                <div className={'absolute'} style={{
                    width: len(BUILDING_WIDTH),
                    height: len(BUILDING_HEIGHT),
                    fontSize: len(BUILDING_TEXT_SIZE),
                }}
                     onClick={() => {
                         if (isMyPhase) {
                             gameRoomRef?.current?.sendMsg(MSG_CLICK_BUILT_BUILDING, {
                                 belongPlayerA: isLeftPlayer,
                                 data: name
                             })
                             props.cardTipRef?.current?.onMouseLeave()
                         }
                     }}
                     onTouchStart={() => props.cardTipRef?.current?.onMouseEnter(cardRecord)}
                     onTouchMove={(e) => props.cardTipRef?.current?.onMouseMove(e.touches.item(0).clientX, e.touches.item(0).clientY)}
                     onTouchEnd={() => props.cardTipRef?.current?.onMouseLeave()}
                     onMouseEnter={() => props.cardTipRef?.current?.onMouseEnter(cardRecord)}
                     onMouseMove={(e) => props.cardTipRef?.current?.onMouseMove(e.clientX, e.clientY)}
                     onMouseLeave={() => props.cardTipRef?.current?.onMouseLeave()}
                >
                    <BuildingView data={cardRecord}/>
                </div>
            </div>
        )
    }

    const classifiedBuildings = classifyBuildings(buildings);
    return (
        <div className={'relative flex w-full h-full items-center'}>
            <span className={'absolute flex items-center justify-center w-full text-3xl opacity-50'}>建筑群</span>
            <div className={'flex h-full w-full pb-24'}>
                {Object.keys(classifiedBuildings).map((key) => {
                    return <div className={'flex flex-col w-1/5 h-full'}>
                        {classifiedBuildings[key].map(v => createOneBuilding(v))}
                    </div>
                })}
            </div>
        </div>
    )
}