export class ProgressToken {
    private readonly name = ''
    private readonly locRow: number = 0
    private readonly locCol: number = 0
    private readonly desc: string = ''

    constructor(obj: any) {
        this.name = obj["名称"] ?? "错误"
        this.locRow = Number(obj["行"] ?? 0)
        this.locCol = Number(obj["列"] ?? 0)
        this.desc = obj["功能"] ?? ""
    }

    // 所有成员变量的get方法
    public getName() {
        return this.name
    }

    public getLoc() {
        return [this.locRow, this.locCol]
    }

    public getDesc() {
        return this.desc
    }
}