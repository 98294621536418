import DiscardedCardsContainer, {DiscardedCardsContainerRef} from "../discarded-cards-container";
import {NormalUserAction} from "../action-container";
import PlayerInfoContainer from "../player-info-container";
import PlayerBuildingsContainer from "../player-buildings-container";
import DraftPoolContainer, {DraftPoolData, DraftPoolRef} from "../draftpool-container";
import PlayerWonderContainer from "../player-wonders-container";
import RowBoardContainer, {MilitaryToken, RowBoardContainerData, RowBoardContainerRef} from "../row-board-container";
import {useEffect, useRef, useState} from "react";
import FlipCoin from "../temper-contaniers/flip-coin";
import WonderDispatcher from "../temper-contaniers/wonder-dispatcher";
import ProgressTokenChooser from "../temper-contaniers/progress-token-chooser";
import {
    len,
    MSG_BUILD_WONDER,
    MSG_CHANGE_GOLD, MSG_CHANGE_PLAYER_PHASE,
    MSG_CLICK_BUILT_BUILDING,
    MSG_CLICK_DISCARDED_POOL_BUILDING,
    MSG_CLICK_DRAFT_BUILDING,
    MSG_CLICK_DRAFT_BUILDING_RETURN,
    MSG_CLICK_PROGRESS_TOKEN, MSG_CLICK_REVERT_PHASE,
    MSG_FLIP_RESULT,
    MSG_FROM_A,
    MSG_PROGRESS_TOKEN_SHOW, MSG_SAVED,
    MSG_WONDER_DISPATCHER_PHASE_X
} from "../../constants";
import GameRoom, {GameRoomRef, MsgListener, SocketMessage} from "../game-room";
import {GAME_INIT} from "../../GameInit";
import ScorePanel from "../temper-contaniers/score-panel";
import {Player} from "../../type/Player";
import CardTip, {CardTipRef} from "../temper-contaniers/card-tip";

declare type GamePhase =
    '匹配玩家'
    | '猜拳'
    | '分发奇迹'
    | '展示发展标记'
    | '正常游戏流程中'
    | '三选一发展标记'
    | '算分阶段'
declare type GameData = {
    playerAData: Player,
    playerBData: Player,
    isPlayerAPhase: boolean,
    gamePhase: GamePhase,
    boardData: RowBoardContainerData | undefined,
    draftPoolData: DraftPoolData | undefined,
    discardedPoolData: string[],
}


export default function GameDesk() {
    const gameRoomRef = useRef<GameRoomRef>(null)
    const boardContainer = useRef<RowBoardContainerRef>(null)
    const discardedPool = useRef<DiscardedCardsContainerRef>(null)
    const draftPoolRef = useRef<DraftPoolRef>(null)
    const cardTipRef = useRef<CardTipRef>(null)

    const [gamePhase, setGamePhase] = useState<GamePhase>('匹配玩家')
    // 创建房间的玩家为playerA
    const [playerA, setPlayerA] = useState<Player>(new Player())
    const [playerB, setPlayerB] = useState<Player>(new Player())

    const [iAmPlayerA, setIamPlayerA] = useState(true)
    const [isPlayerAPhase, setIsPlayerAPhase] = useState<boolean>(true)
    const [phaseRecorder, setPhaseRecorder] = useState<string[]>([])

    const isMobile = window.matchMedia("only screen and (max-width: 760px)").matches;
    const [focusOnAWonder, setFocusOnAWonder] = useState<boolean>(true)
    const [focusOnABuildings, setFocusOnABuildings] = useState<boolean>(true)
    const [focusOnAPlayerInfo, setFocusOnAPlayerInfo] = useState<boolean>(true)

    const intervalRefPlayerA = useRef(playerA);
    const intervalRefPlayerB = useRef(playerB);
    const intervalRefIsPlayerAPhase = useRef(isPlayerAPhase);
    const intervalRefGamePhase = useRef(gamePhase);

    // 用ref来避免state更新不及时问题，解决setTimeOut中读取到旧数据
    useEffect(() => {
        intervalRefPlayerA.current = playerA;
        intervalRefPlayerB.current = playerB;
        intervalRefIsPlayerAPhase.current = isPlayerAPhase;
        intervalRefGamePhase.current = gamePhase;
    }, [playerA, playerB, isPlayerAPhase, gamePhase]);

    useEffect(() => {
        GAME_INIT()
    }, []);

    const updatePlayerInfo = () => {
        let newPlayerA = Player.cloneFrom(playerA);
        let newPlayerB = Player.cloneFrom(playerB);
        newPlayerA.setOppositePlayer(newPlayerB)
        newPlayerB.setOppositePlayer(newPlayerA)
        setPlayerA(newPlayerA)
        setPlayerB(newPlayerB)
    }

    const onBuildBuilding = (isPlayerA: boolean, name: string): boolean => {
        const player = isPlayerA ? playerA : playerB
        player.setShowToastAbility(gameRoomRef.current?.showToast)
        const success = player?.buildBuilding(name)
        if (success) {
            updatePlayerInfo()
        }
        return success
    }

    const onDiscardBuilding = (isPlayerA: boolean, name: string): boolean => {
        const player = isPlayerA ? playerA : playerB
        player.sellBuilding()
        discardedPool.current?.addANewDiscardedCard(name)
        updatePlayerInfo()
        return true
    }

    const onDraftPoolBuildingClick = (isPlayerA: boolean, action: NormalUserAction, name: string): boolean => {
        switch (action) {
            case "建造奇迹":
                // 建造奇迹有后续步骤(选奇迹)，这里直接返回false
                return false
            case "建造建筑":
                return onBuildBuilding(isPlayerA, name);
            case "丢弃换金币":
                return onDiscardBuilding(isPlayerA, name)
            default:
                return false
        }
    }

    const onRecycledBuildingClick = (clickedByPlayerA: boolean, name: string): boolean => {
        // 添加到玩家建筑群
        const player = clickedByPlayerA ? playerA : playerB
        player.recycleBuilding(name)
        updatePlayerInfo()
        return true
    }

    const onPlayerBuiltBuildingClick = (isPlayerA: boolean, name: string) => {
        const player = isPlayerA ? playerA : playerB
        player.removeBuilding(name)
        discardedPool.current?.addANewDiscardedCard(name)
        updatePlayerInfo()
    }

    const onProgressTokenClick = (clickedByPlayerA: boolean, name: string): boolean => {
        // 加入到自己的区域来
        const player = clickedByPlayerA ? playerA : playerB
        player.addProgressToken(name)
        setGamePhase('正常游戏流程中')
        updatePlayerInfo()
        return true
    }

    const onWonderClick = (belongLeftPlayer: boolean, name: string) => {
        if (belongLeftPlayer !== isPlayerAPhase) return
        const action = draftPoolRef.current?.getWaitedAction()
        const buildingName = draftPoolRef.current?.getSelectedBuilding()
        if (action === '等待建造奇迹' && buildingName !== undefined) {
            if (iAmPlayerA === isPlayerAPhase) {
                gameRoomRef?.current?.sendMsg(MSG_BUILD_WONDER, { wonderName: name, createBy: buildingName })
            }
        }
    }

    const onBuildWonder = (name: string, createBy: string) => {
        const currentPlayer = isPlayerAPhase ? playerA : playerB
        currentPlayer.setShowToastAbility(gameRoomRef.current?.showToast)
        const success = currentPlayer.buildWonder(name, createBy)
        if (success) {
            if (iAmPlayerA === isPlayerAPhase) {
                gameRoomRef?.current?.sendMsg(MSG_CLICK_DRAFT_BUILDING_RETURN, {
                    result: true,
                    tip: `使用【${createBy}】建造了奇迹【${name}】`
                })
            }
            updatePlayerInfo()
            // 各种特殊奇迹效果的处理
            // TODO(wangguichun): 2024/7/27 考虑收敛到player中去
            if (name === "亚历山大图书馆") {
                setGamePhase('三选一发展标记')
            }
        }
    }

    const insertGameFlow = () => {
        if (gamePhase === '匹配玩家') {
        } else if (gamePhase === '猜拳') {
            return <FlipCoin gameRoomRef={gameRoomRef}
                             iAmPlayerA={iAmPlayerA}
                             onClickOk={() => {
                                 setGamePhase('分发奇迹')
                             }}/>
        } else if (gamePhase === '分发奇迹') {
            return <WonderDispatcher
                iAmPlayerA={iAmPlayerA}
                firstPlayerIsA={isPlayerAPhase}
                gameRoomRef={gameRoomRef}
                onComplete={() => setGamePhase('展示发展标记')}/>
        } else if (gamePhase === '展示发展标记') {
            if (iAmPlayerA) {
                gameRoomRef?.current?.sendMsg(MSG_PROGRESS_TOKEN_SHOW)
            }
            setGamePhase('正常游戏流程中')
        } else if (gamePhase === '正常游戏流程中') {
        } else if (gamePhase === '三选一发展标记') {
            const tokens = boardContainer?.current?.get3RemainProgressToken().map(v => v.name)
            return <ProgressTokenChooser
                gameRoomRef={gameRoomRef}
                tokens={tokens}/>
        } else if (gamePhase === '算分阶段') {
            return <ScorePanel
                gameRoomRef={gameRoomRef}
                iAmPlayerA={iAmPlayerA}
                playerA={playerA}
                playerB={playerB}/>
        }

        return <></>
    }

    const socketMessageListener: MsgListener = (msg: SocketMessage) => {
        const gameRoom = gameRoomRef.current
        if (msg.text === MSG_FLIP_RESULT) {
            const winner = msg.extra
            setIsPlayerAPhase(winner === MSG_FROM_A)
        } else if (msg.text.startsWith(MSG_WONDER_DISPATCHER_PHASE_X)) {
            // 分发奇迹时，玩家选择了某个奇迹
            const player = msg.from === MSG_FROM_A ? playerA : playerB
            const wonderName = msg.extra
            player.addWonder({ name: wonderName, cardType: '奇迹', showBack: false, createBy: '' })
            updatePlayerInfo()
        } else if (msg.text === MSG_CLICK_DRAFT_BUILDING) {
            const msgIsFromA = msg.from === MSG_FROM_A
            const { action, name } = msg.extra
            const result = onDraftPoolBuildingClick(msgIsFromA, action, name)
            if (iAmPlayerA === msgIsFromA) {
                let tip = ''
                if (result && action === '建造建筑') {
                    tip = `建造了【${name}】`
                } else if (result && action === '丢弃换金币') {
                    tip = `丢弃了【${name}】`
                }
                gameRoom?.sendMsg(MSG_CLICK_DRAFT_BUILDING_RETURN, { result, tip })
            }
        } else if (msg.text === MSG_BUILD_WONDER) {
            const { wonderName, createBy: buildingName } = msg.extra
            onBuildWonder(wonderName, buildingName)
        } else if (msg.text === MSG_CLICK_BUILT_BUILDING) {
            const { belongPlayerA, data } = msg.extra
            onPlayerBuiltBuildingClick(belongPlayerA, data)
        } else if (msg.text === MSG_CLICK_DISCARDED_POOL_BUILDING) {
            onRecycledBuildingClick(msg.from === MSG_FROM_A, msg.extra)
        } else if (msg.text === MSG_CLICK_PROGRESS_TOKEN) {
            onProgressTokenClick(msg.from === MSG_FROM_A, msg.extra)
        } else if (msg.text === MSG_CHANGE_GOLD) {
            const { isLeftPlayer, toNum } = msg.extra
            const player = isLeftPlayer ? playerA : playerB
            player.setGolds(toNum)
            updatePlayerInfo()
        } else if (msg.text === MSG_CHANGE_PLAYER_PHASE) {
            const nextIsPlayerA = msg.from !== MSG_FROM_A
            setIsPlayerAPhase(nextIsPlayerA)
            saveRecord()
        } else if (msg.text === MSG_CLICK_REVERT_PHASE) {
            if (phaseRecorder.length < 1) return
            const record = phaseRecorder[phaseRecorder.length - 1]
            onReceiveSyncedData(record, iAmPlayerA)
        }
    }

    const getNeedSyncDataString = (): string => {
        const gameData: GameData = {
            playerAData: intervalRefPlayerA.current,
            playerBData: intervalRefPlayerB.current,
            isPlayerAPhase: intervalRefIsPlayerAPhase.current,
            gamePhase: intervalRefGamePhase.current,
            boardData: boardContainer.current?.getNeedSyncData(),
            draftPoolData: draftPoolRef?.current?.getNeedSyncData(),
            discardedPoolData: discardedPool.current?.getNeedSyncData() ?? [],
        }

        return JSON.stringify(gameData, (key, value) => {
            // 避免递归stringify
            if (key === 'oppositePlayer') {
                return undefined
            }
            return value
        })
    }

    const onReceiveSyncedData = (data: string, focusA: boolean) => {
        const gameData = JSON.parse(data)
        let newPlayerA = Player.cloneFrom(gameData.playerAData);
        let newPlayerB = Player.cloneFrom(gameData.playerBData);
        newPlayerA.setOppositePlayer(newPlayerB)
        newPlayerB.setOppositePlayer(newPlayerA)
        setPlayerA(newPlayerA)
        setPlayerB(newPlayerB)
        setIsPlayerAPhase(gameData.isPlayerAPhase)
        setGamePhase(gameData.gamePhase)
        gameData.boardData && boardContainer.current?.onReceiveSyncData(gameData.boardData)
        gameData.draftPoolData && draftPoolRef.current?.onReceiveSyncData(gameData.draftPoolData)
        discardedPool.current?.onReceiveSyncData(gameData.discardedPoolData)
        setFocusOnAPlayerInfo(focusA)
        setFocusOnAWonder(focusA)
        setFocusOnABuildings(focusA)
    }

    const saveRecord = () => {
        setTimeout(() => {
            const recordData = getNeedSyncDataString()
            phaseRecorder.push(recordData)
            setPhaseRecorder(phaseRecorder.slice())
            if (iAmPlayerA === isPlayerAPhase) {
                const tip = `PlayerA: 金币【${playerA.getGolds()}】, PlayerB: 金币【${playerB.getGolds()}】, 现在轮到【${intervalRefPlayerA.current ? 'PlayerA' : 'PlayerB'}】`
                gameRoomRef?.current?.sendMsg(MSG_SAVED, { step: phaseRecorder.length, data: recordData, tip })
            }
        }, 200)
    }

    const onFirstPhaseStart = () => {
        saveRecord()
    }

    const onMilitaryRemove = (token: MilitaryToken) => {
        if (token.isLeftPlayer) {
            playerA.removeGolds(token.golds)
            updatePlayerInfo()
        } else {
            playerB.removeGolds(token.golds)
            updatePlayerInfo()
        }
    }

    const mobileRender = () => {
        return <>
            <div className={'flex flex-col bg-green-200 w-full p-2 items-center justify-center'}>
                <RowBoardContainer ref={boardContainer}
                                   conflictLevel={playerA.getConflictLevel()}
                                   isMyPhase={iAmPlayerA === isPlayerAPhase}
                                   cardTipRef={cardTipRef}
                                   gameRoomRef={gameRoomRef}
                                   onMilitaryRemove={onMilitaryRemove}
                                   onSetConflictLevel={playerA.setConflictLevel}
                />
                <DraftPoolContainer ref={draftPoolRef}
                                    iAmPlayerA={iAmPlayerA}
                                    isMyPhase={iAmPlayerA === isPlayerAPhase}
                                    cardTipRef={cardTipRef}
                                    gameRoomRef={gameRoomRef}
                                    onGameStart={() => onFirstPhaseStart()}
                                    onGameOver={() => setGamePhase('算分阶段')}/>
                <div
                    className={'flex flex-row w-full rounded-2xl bg-opacity-90 mb-1 ' + (focusOnAPlayerInfo ? 'bg-blue-300' : 'bg-red-300')}>
                    {focusOnAPlayerInfo ?
                        <PlayerInfoContainer isLeftPlayer={true}
                                             iAmLeftPlayer={iAmPlayerA}
                                             isMyPhase={iAmPlayerA === isPlayerAPhase}
                                             info={playerA}
                                             gameRoomRef={gameRoomRef}/> :
                        <PlayerInfoContainer isLeftPlayer={false}
                                             iAmLeftPlayer={iAmPlayerA}
                                             isMyPhase={iAmPlayerA === isPlayerAPhase}
                                             info={playerB}
                                             gameRoomRef={gameRoomRef}/>}
                    <button className={'p-4 text-white'}
                            onTouchStart={() => setFocusOnAPlayerInfo(!iAmPlayerA)}
                            onMouseDown={() => setFocusOnAPlayerInfo(!iAmPlayerA)}
                            onTouchEnd={() => setFocusOnAPlayerInfo(iAmPlayerA)}
                            onMouseUp={() => setFocusOnAPlayerInfo(iAmPlayerA)}
                    >按住查看对方({iAmPlayerA ? 'PlayerB' : 'PlayerA'})信息
                    </button>
                </div>
                <div
                    className={'flex flex-row w-full rounded-2xl bg-opacity-90 mb-1 ' + (focusOnABuildings ? 'bg-blue-300' : 'bg-red-300')}>
                    {focusOnABuildings ?
                        <PlayerBuildingsContainer isLeftPlayer={true}
                                                  isMyPhase={iAmPlayerA === isPlayerAPhase}
                                                  buildings={playerA.getBuiltBuildings()}
                                                  cardTipRef={cardTipRef}
                                                  gameRoomRef={gameRoomRef}/> :
                        <PlayerBuildingsContainer isLeftPlayer={false}
                                                  isMyPhase={iAmPlayerA === isPlayerAPhase}
                                                  buildings={playerB.getBuiltBuildings()}
                                                  cardTipRef={cardTipRef}
                                                  gameRoomRef={gameRoomRef}/>}
                    <button className={'p-4 text-white'}
                            onTouchStart={() => setFocusOnABuildings(!iAmPlayerA)}
                            onMouseDown={() => setFocusOnABuildings(!iAmPlayerA)}
                            onTouchEnd={() => setFocusOnABuildings(iAmPlayerA)}
                            onMouseUp={() => setFocusOnABuildings(iAmPlayerA)}
                    >按住查看对方({iAmPlayerA ? 'PlayerB' : 'PlayerA'})信息
                    </button>
                </div>
                <div
                    className={'flex flex-row w-full rounded-lg bg-opacity-90 mb-1 ' + (focusOnAWonder ? 'bg-blue-300' : 'bg-red-300')}>
                    <div className={'w-full'}>
                        {focusOnAWonder ?
                            <PlayerWonderContainer isLeftPlayer={true}
                                                   wonders={playerA.getWonders()}
                                                   onWonderClick={onWonderClick}
                                                   cardTipRef={cardTipRef}/> :
                            <PlayerWonderContainer isLeftPlayer={false}
                                                   wonders={playerB.getWonders()}
                                                   onWonderClick={onWonderClick}
                                                   cardTipRef={cardTipRef}/>}
                    </div>
                    <button className={'p-4 text-white'}
                            onTouchStart={() => setFocusOnAWonder(!iAmPlayerA)}
                            onMouseDown={() => setFocusOnAWonder(!iAmPlayerA)}
                            onTouchEnd={() => setFocusOnAWonder(iAmPlayerA)}
                            onMouseUp={() => setFocusOnAWonder(iAmPlayerA)}
                    >按住查看对方({iAmPlayerA ? 'PlayerB' : 'PlayerA'})信息
                    </button>
                </div>
                <DiscardedCardsContainer ref={discardedPool}
                                         isMyPhase={isPlayerAPhase === iAmPlayerA}
                                         cardTipRef={cardTipRef}
                                         gameRoomRef={gameRoomRef}/>
            </div>
            <CardTip ref={cardTipRef}
                     leftPlayer={playerA}
                     rightPlayer={playerB}
                     iAmLeftPlayer={iAmPlayerA}/>
            <GameRoom
                ref={gameRoomRef}
                started={gamePhase !== '匹配玩家'}
                messageListener={socketMessageListener}
                startGame={() => {
                    if (gamePhase === '匹配玩家') {
                        setGamePhase('猜拳')
                    }
                }}
                onGetIdentity={(isPlayerA) => setIamPlayerA(isPlayerA)}
                getNeedSyncData={getNeedSyncDataString}
                onReceiveSyncedData={(data, syncFromA) => onReceiveSyncedData(data, !syncFromA)}
            />
            {insertGameFlow()}
        </>
    }

    const deskRender = () => <>
        <div className={'flex flex-row bg-green-200 w-full h-full p-4 justify-center'}
             style={{ minWidth: len(1300), minHeight: len(850) }}>
            <div className={'flex flex-col h-full w-96'}>
                <PlayerWonderContainer isLeftPlayer={true}
                                       wonders={playerA.getWonders()}
                                       onWonderClick={onWonderClick}
                                       cardTipRef={cardTipRef}/>
                <div className={'py-2'}>
                    <PlayerInfoContainer isLeftPlayer={true}
                                         iAmLeftPlayer={iAmPlayerA}
                                         isMyPhase={iAmPlayerA === isPlayerAPhase}
                                         info={playerA}
                                         gameRoomRef={gameRoomRef}/>
                </div>
                <PlayerBuildingsContainer isLeftPlayer={true}
                                          isMyPhase={iAmPlayerA === isPlayerAPhase}
                                          buildings={playerA.getBuiltBuildings()}
                                          cardTipRef={cardTipRef}
                                          gameRoomRef={gameRoomRef}/>
            </div>
            <div className={'flex flex-col flex-grow items-center h-full'}>
                <RowBoardContainer ref={boardContainer}
                                   conflictLevel={playerA.getConflictLevel()}
                                   isMyPhase={iAmPlayerA === isPlayerAPhase}
                                   cardTipRef={cardTipRef}
                                   gameRoomRef={gameRoomRef}
                                   onMilitaryRemove={onMilitaryRemove}
                                   onSetConflictLevel={(level) => playerA.setConflictLevel(level)}
                />
                <DraftPoolContainer ref={draftPoolRef}
                                    iAmPlayerA={iAmPlayerA}
                                    isMyPhase={iAmPlayerA === isPlayerAPhase}
                                    cardTipRef={cardTipRef}
                                    gameRoomRef={gameRoomRef}
                                    onGameStart={() => onFirstPhaseStart()}
                                    onGameOver={() => setGamePhase('算分阶段')}/>
                <div className={'px-2 w-full'}>
                    <DiscardedCardsContainer ref={discardedPool}
                                             isMyPhase={isPlayerAPhase === iAmPlayerA}
                                             cardTipRef={cardTipRef}
                                             gameRoomRef={gameRoomRef}/>
                </div>
            </div>
            <div className={'flex flex-col h-full w-96'}>
                <PlayerWonderContainer isLeftPlayer={false}
                                       wonders={playerB.getWonders()}
                                       onWonderClick={onWonderClick}
                                       cardTipRef={cardTipRef}/>
                <div className={'my-2'}>
                    <PlayerInfoContainer isLeftPlayer={false}
                                         iAmLeftPlayer={iAmPlayerA}
                                         isMyPhase={iAmPlayerA === isPlayerAPhase}
                                         info={playerB}
                                         gameRoomRef={gameRoomRef}/>
                </div>
                <PlayerBuildingsContainer isLeftPlayer={false}
                                          isMyPhase={iAmPlayerA === isPlayerAPhase}
                                          buildings={playerB.getBuiltBuildings()}
                                          cardTipRef={cardTipRef}
                                          gameRoomRef={gameRoomRef}/>
            </div>
        </div>
        <CardTip ref={cardTipRef}
                 leftPlayer={playerA}
                 rightPlayer={playerB}
                 iAmLeftPlayer={iAmPlayerA}/>
        <GameRoom
            ref={gameRoomRef}
            started={gamePhase !== '匹配玩家'}
            messageListener={socketMessageListener}
            startGame={() => {
                if (gamePhase === '匹配玩家') {
                    setGamePhase('猜拳')
                }
            }}
            onGetIdentity={(isPlayerA) => setIamPlayerA(isPlayerA)}
            getNeedSyncData={getNeedSyncDataString}
            onReceiveSyncedData={(data, syncFromA) => onReceiveSyncedData(data, !syncFromA)}
        />
        {insertGameFlow()}
    </>

    return isMobile ? mobileRender() : deskRender()
}