import boardBg from '../../assets/board_2X_rotated.webp';
import spritesFile from "../../assets/sprites_2X.png"
import {
    BOARD_HEIGHT,
    BOARD_WIDTH,
    len,
    MSG_CLICK_MILITARY_TOKEN,
    MSG_CLICK_PROGRESS_TOKEN, MSG_FROM_SYSTEM,
    MSG_PROGRESS_TOKEN_SHOW, MSG_ROOM_CREATED,
} from "../../constants";
import ProgressTokenView from "../../card/progress-token-view";
import React, {forwardRef, Ref, RefObject, useEffect, useImperativeHandle, useRef, useState} from "react";
import {prepareProgressTokens} from "../../utils";
import {CardTipRef} from "../temper-contaniers/card-tip";
import {GameRoomRef, SocketMessage} from "../game-room";
import {CardRecord} from "../../card";

export declare type MilitaryToken = {
    golds: number
    isLeftPlayer: boolean,
}

export declare type RowBoardContainerData = {
    draftPoolProgressTokens: (CardRecord | undefined)[],
    remainProgressTokens: (CardRecord | undefined)[],
    militaryTokens: (MilitaryToken | undefined)[],
}

export declare type RowBoardContainerRef = {
    get3RemainProgressToken: () => CardRecord[]
    getNeedSyncData: () => RowBoardContainerData
    onReceiveSyncData: (data: RowBoardContainerData) => void
}

export const RowBoardContainer = forwardRef((props: {
    conflictLevel: number,
    isMyPhase: boolean,
    cardTipRef: RefObject<CardTipRef>,
    gameRoomRef: RefObject<GameRoomRef>,
    onMilitaryRemove: (token: MilitaryToken) => void,
    onSetConflictLevel: (level: number) => void,               // TODO(wangguichun): 2024/7/27 考虑删除了
}, ref: Ref<RowBoardContainerRef>) => {
    const bgRef = useRef(null)
    const [boardWidth, setBoardWidth] = useState(0)
    const [draftPoolProgressTokens, setDraftPoolProgressTokens] = useState<(CardRecord | undefined)[]>([])
    const [remainProgressTokens, setRemainProgressTokens] = useState<(CardRecord | undefined)[]>([])
    const [militaryTokens, setMilitaryTokens] = useState<(MilitaryToken | undefined)[]>([
        { golds: 5, isLeftPlayer: true },
        { golds: 2, isLeftPlayer: true },
        { golds: 2, isLeftPlayer: false },
        { golds: 5, isLeftPlayer: false },
    ])

    useImperativeHandle(ref, () => ({
        get3RemainProgressToken: (): CardRecord[] => {
            // @ts-ignore
            return remainProgressTokens.filter((v) => v).slice(0, 3)
        },
        getNeedSyncData: () => {
            return {
                draftPoolProgressTokens,
                remainProgressTokens,
                militaryTokens,
            }
        },
        onReceiveSyncData: (data: RowBoardContainerData) => {
            const { draftPoolProgressTokens, remainProgressTokens, militaryTokens } = data
            setDraftPoolProgressTokens(draftPoolProgressTokens)
            setRemainProgressTokens(remainProgressTokens)
            setMilitaryTokens(militaryTokens)
        }
    }))

    useEffect(() => {
        window.addEventListener('resize', () => {
            if (bgRef) {
                // @ts-ignore
                setBoardWidth(bgRef.current?.offsetWidth ?? 0)
            }
        })
        setTimeout(() => {
            // @ts-ignore
            setBoardWidth(bgRef.current?.offsetWidth ?? 0)
        }, 200)
    }, [])

    props.gameRoomRef?.current?.addMsgListener('row-board-container', (msg: SocketMessage) => {
        if (msg.from === MSG_FROM_SYSTEM && msg.text === MSG_ROOM_CREATED) {
            const allTokens = prepareProgressTokens().map(name => {
                    const d: CardRecord = {
                        cardType: '发展标记', createBy: '', name: name, showBack: false
                    }
                    return d
                }
            )
            const draftTokens = allTokens.slice(0, 5)
            draftTokens.forEach(v => v.showBack = true)
            setDraftPoolProgressTokens(draftTokens)
            setRemainProgressTokens(allTokens.slice(5))
        } else if (msg.text === MSG_PROGRESS_TOKEN_SHOW) {
            const newTokens = draftPoolProgressTokens.map((v) => {
                if (v) v.showBack = false
                return v
            })
            setDraftPoolProgressTokens(newTokens)
        } else if (msg.text === MSG_CLICK_PROGRESS_TOKEN) {
            const tokenName = msg.extra
            const newTokens = draftPoolProgressTokens.map((value) => {
                if (value?.name === tokenName) {
                    return undefined
                }
                return value
            })
            setDraftPoolProgressTokens(newTokens)
        } else if (msg.text === MSG_CLICK_MILITARY_TOKEN) {
            removeMilitaryToken(msg.extra)
        }
    })

    const removeMilitaryToken = (token: MilitaryToken): void => {
        const newTokens = militaryTokens.map((value) => {
            if (value?.isLeftPlayer === token.isLeftPlayer && value?.golds === token.golds) {
                props.onMilitaryRemove(token)
                return undefined
            } else {
                return value
            }
        })
        setMilitaryTokens(newTokens)
    }

    const creatOneProgressToken = (pos: number, boardWidth: number, data: CardRecord | undefined) => {
        const boardHeight = BOARD_HEIGHT / BOARD_WIDTH * boardWidth
        const progressTokenWidth = 180 / BOARD_WIDTH * boardWidth

        const progressTokenLeft = 468 / 1897 * boardWidth
        const progressTokenHeightWithMargin = progressTokenWidth * 1.07
        const progressTokenTop = 15 / 535 * boardHeight
        return (
            <div className={'absolute'} key={pos}
                 style={{
                     height: len(progressTokenWidth), width: len(progressTokenWidth),
                     left: len(progressTokenLeft + pos * progressTokenHeightWithMargin),
                     top: len(progressTokenTop),
                 }}
                 onClick={() => {
                     if (data && props.isMyPhase) {
                         props.gameRoomRef?.current?.sendMsg(MSG_CLICK_PROGRESS_TOKEN, data.name)
                     }
                     props.cardTipRef?.current?.onMouseLeave()
                 }}
                 onTouchStart={() => props.cardTipRef?.current?.onMouseEnter(data)}
                 onTouchMove={(e) => props.cardTipRef?.current?.onMouseMove(e.touches.item(0).clientX, e.touches.item(0).clientY)}
                 onTouchEnd={() => props.cardTipRef?.current?.onMouseLeave()}
                 onMouseEnter={() => props.cardTipRef?.current?.onMouseEnter(data)}
                 onMouseMove={(e) => props.cardTipRef?.current?.onMouseMove(e.clientX, e.clientY)}
                 onMouseLeave={() => props.cardTipRef?.current?.onMouseLeave()}
            >
                {data && <ProgressTokenView data={data}/>}
            </div>
        )
    }

    function creatConflictToken(pos: number, boardWidth: number) {
        const boardHeight = BOARD_HEIGHT / BOARD_WIDTH * boardWidth
        const tokenWidth = 56 / 535 * boardHeight
        const tokenHeight = 160 / 1897 * boardWidth
        const tokenCenterY = 307 / 535 * boardHeight
        const tokenCenterX = 118 / 1897 * boardWidth
        const cellWidthWithMargin = 91.5 / 1897 * boardWidth

        return (
            <div className={'absolute'} id={'conflict_area'} style={{
                height: len(tokenHeight),
                width: len(cellWidthWithMargin * 19),
                top: len(tokenCenterY - 0.5 * tokenHeight),
                left: len(tokenCenterX - 0.5 * cellWidthWithMargin),
            }}
                 onClick={(e) => {
                     // TODO(wangguichun): 2024/7/27 可以考虑移除点击事件了
                     if (!props.isMyPhase) return;
                     // @ts-ignore
                     if (e.target.id !== 'conflict_area') return
                     const clickX = e.nativeEvent.offsetX
                     const whichCell = Math.floor(clickX / cellWidthWithMargin)
                     props.onSetConflictLevel(whichCell - 9)
                 }}>
                <div className={'relative rotate-90'}
                     style={{
                         height: len(tokenWidth), width: len(tokenHeight),   // 因为转了90度，这里宽高要调换
                         top: len(0.5 * tokenHeight - 0.5 * tokenWidth),
                         left: len((pos + 9) * cellWidthWithMargin - 0.5 * tokenWidth),
                         backgroundImage: `url(${spritesFile})`,
                         backgroundSize: `${512 / 160 * 100}% ${512 / 56 * 100}%`,
                         backgroundRepeat: 'no-repeat',
                         backgroundPositionX: `-${246 / 160 * tokenHeight}px`,
                         backgroundPositionY: `-${430 / 56 * tokenWidth}px`,
                     }}>
                </div>
            </div>
        )
    }

    function creatMilitaryToken(token: MilitaryToken | undefined, boardWidth: number) {
        if (!token) return <></>
        const isSmallOne = token.golds === 2
        const isMe = token.isLeftPlayer
        const boardHeight = BOARD_HEIGHT / BOARD_WIDTH * boardWidth
        const tokenHeight = ((isSmallOne) ? 85 : 100) / 535 * boardHeight
        const tokenWidth = ((isSmallOne) ? 190 : 220) / 1897 * boardWidth
        const tokenCenterY = 445 / 535 * boardHeight
        let tokenCenterX = 0
        if (isMe && !isSmallOne) {
            tokenCenterX = 305 / 1897 * boardWidth
        } else if (isMe && isSmallOne) {
            tokenCenterX = 580 / 1897 * boardWidth
        } else if (!isMe && isSmallOne) {
            tokenCenterX = 1315 / 1897 * boardWidth
        } else if (!isMe && !isSmallOne) {
            tokenCenterX = 1590 / 1897 * boardWidth
        }
        return (
            <div className={'absolute rotate-90'}
                 style={{
                     height: len(tokenWidth), width: len(tokenHeight),   // 因为转了90度，这里宽高要调换
                     left: len(tokenCenterX - 0.5 * tokenHeight),
                     top: len(tokenCenterY - 0.5 * tokenWidth),
                     backgroundImage: `url(${spritesFile})`,
                     backgroundSize: isSmallOne ? `${512 / 126 * 100}% ${512 / 286 * 100}%` : `${512 / 150 * 100}% ${512 / 340 * 100}%`,
                     backgroundRepeat: 'no-repeat',
                     backgroundPositionX: isSmallOne ? `-${146 / 126 * tokenHeight}px` : `-${0 / 150 * tokenHeight}px`,
                     backgroundPositionY: isSmallOne ? `-${0 / 286 * tokenWidth}px` : `-${0 / 340 * tokenWidth}px`,
                 }}
                // TODO(wangguichun): 2024/7/27 考虑删除手动操作
                 onClick={() => {
                     if (!props.isMyPhase) return;
                     props.gameRoomRef?.current?.sendMsg(MSG_CLICK_MILITARY_TOKEN, token)
                 }}/>
        )
    }

    if (props.conflictLevel >= 6) {
        if (militaryTokens[3]) {
            removeMilitaryToken(militaryTokens[3])
        }
    } else if (props.conflictLevel >= 3) {
        if (militaryTokens[2]) {
            removeMilitaryToken(militaryTokens[2])
        }
    } else if (props.conflictLevel <= -6) {
        if (militaryTokens[0]) {
            removeMilitaryToken(militaryTokens[0])
        }
    } else if (props.conflictLevel <= -3) {
        if (militaryTokens[1]) {
            removeMilitaryToken(militaryTokens[1])
        }
    }

    return (
        <div className={'relative flex'}>
            <img className={'object-scale-down'} src={boardBg} ref={bgRef} alt={""} style={{ maxHeight: len(200) }}/>
            {draftPoolProgressTokens.map((value, index) => creatOneProgressToken(index, boardWidth, value))}

            {creatConflictToken(props.conflictLevel, boardWidth)}

            {creatMilitaryToken(militaryTokens[0], boardWidth)}
            {creatMilitaryToken(militaryTokens[1], boardWidth)}
            {creatMilitaryToken(militaryTokens[2], boardWidth)}
            {creatMilitaryToken(militaryTokens[3], boardWidth)}
        </div>
    )
})

export default RowBoardContainer