import {parseOfferGolds, parseOfferMaterialStr, parseOfferScores} from "../utils";

export class Wonder {
    private readonly name = ''
    private readonly locRow: number = 0
    private readonly locCol: number = 0
    private readonly requireMaterials = [0, 0, 0, 0, 0]    // 木头/石料/粘土/纸张/玻璃 数量
    private readonly offerOmnipotent = [0, 0]     // 木头/石料/粘土, 纸张/玻璃 替换材料数量
    private readonly offerScores: number | { types: string[], num: number } = 0
    private readonly offerConflicts: number = 0
    private readonly offerGolds: number | { types: string[], num: number } = 0
    private readonly desc: string = ''
    private readonly createBy: string = ''

    constructor(obj: any) {
        this.name = obj["名称"] ?? "错误"
        this.locRow = Number(obj["行"] ?? 0)
        this.locCol = Number(obj["列"] ?? 0)
        this.offerConflicts = Number(obj["战争"] ?? "")
        this.offerGolds = parseOfferGolds(obj["提供金币"] ?? "")
        this.offerOmnipotent = parseOfferMaterialStr(obj["原材料"] ?? "").omnipotent
        this.offerScores = parseOfferScores(obj["积分"] ?? "")
        this.requireMaterials = [Number(obj["木头"] ?? 0), Number(obj["石料"] ?? 0), Number(obj["粘土"] ?? 0), Number(obj["纸张"] ?? 0), Number(obj["玻璃"] ?? 0)]
        this.desc = obj["功能"] ?? ""
        this.createBy = ''
    }


    // 所有成员变量的get方法
    public getName() {
        return this.name
    }


    public getLoc() {
        return [this.locRow, this.locCol]
    }


    public getRequireMaterials() {
        return this.requireMaterials
    }

    public getOfferOmnipotent() {
        return this.offerOmnipotent
    }

    public getOfferScores() {
        return this.offerScores
    }

    public getOfferConflicts() {
        return this.offerConflicts
    }

    public getOfferGolds() {
        return this.offerGolds
    }

    public getDesc() {
        return this.desc
    }

    public getCreateBy() {
        return this.createBy
    }
}